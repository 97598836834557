
import { baseUrl } from "../../constants";
import {
    getDDClientCall,
    getDDActivityCall,
    getDDActUnitCall,
    getDDbankCall,
    getDDJobNumCall,
    getDDCliAddrCall,
    getDDAgencyRoleCall,
    getDDPdaCall,
    getDDOperationCall,
    setDDPdaAction
} from "../../redux/actions/dropDown";

export default {
    "tableTitle": "Approved PDA",
    "apiDetails": {
        startUp: (dispatch) => {
            [
                getDDClientCall,
                getDDActivityCall,
                getDDActUnitCall,
                getDDbankCall,
                getDDJobNumCall,
                getDDCliAddrCall,
                getDDAgencyRoleCall,
                getDDPdaCall,
                getDDOperationCall,
                setDDPdaAction
            ].forEach((func) => {
                dispatch(func());
            });
        },
        list: {
            url: `${baseUrl}/api/service/pda/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/service/pda`,
        edit: `${baseUrl}/api/service/pda/edit`,
        delete: `${baseUrl}/api/service/pda/remove`
    },
    "tableCol": [
        {
            name: 'PDA Number',
            selector: row => row.pda_no,
            sortable: true
        },
        {
            name: 'PDA Date',
            selector: row => row.pdaDate,
            sortable: true
        },
        {
            name: 'Client Name',
            selector: row => row.client,
            sortable: true
        },
        {
            name: 'Request',
            selector: row => row.requestType_DD?.name,
            sortable: true
        },
        {
            name: 'Vessel',
            selector: row => row.ship_DD?.name,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => row.port,
            sortable: true
        },
        {
            name: 'ETA',
            selector: row => row.eta,
            sortable: true
        },
        {
            name: 'Total Cost',
            selector: row => row.totalAmt,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "box",
            "formTitle": "Request Info",
            "formFields": [
                {
                    type: 'datetime',
                    label: 'PDA Date',
                    name: 'pdaDate',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Bank',
                    name: 'bank',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Client Name',
                    name: 'client',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Billing Name',
                    name: 'billTo',
                    required: true
                },
                // {
                //     type: 'select',
                //     options: [
                //         "Type1",
                //         "Type2"
                //     ],
                //     label: 'Client Address',
                //     name: 'clientAddr'
                // },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Job #',
                    name: 'jobNum',
                    required: true
                },
                {
                    type: 'datetime',
                    label: 'ETA',
                    name: 'eta'
                },
                {
                    type: 'datetime',
                    label: 'ETB',
                    name: 'etb'
                },
                {
                    type: 'datetime',
                    label: 'ETD',
                    name: 'etd'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'PDA Currency',
                    name: 'pdaCcy',
                    required: true
                },
                 {
                    type: 'text',
                    label: 'Terminal',
                    name: 'aalutation'
                }
            ]
        },        
        {
            "formType": "addList",
            "formIndex": 2,
            "addForm": {
                "formType": "list",
                "formTitle": "Services",
                "formName": "services",
                "opsAdd": true,
                "formFields": [
                    {
                        type: 'select',
                        options: [
                            "Type1",
                            "Type2"
                        ],
                        label: 'Activity',
                        name: 'activity',
                        required: true
                    },
                    {
                        "type": "number",
                        "label": "Quantity",
                        "name": "quantity",
                        required: true
                    },
                    {
                        type: 'select',
                        options: [
                            "Per Call",
                            "Per Vessel"
                        ],
                        label: 'Unit',
                        name: 'unit',
                        required: true
                    },
                    {
                        "type": "number",
                        "label": "USD",
                        "name": "prefCcy",
                        required: true
                    },
                    {
                        "type": "number",
                        "label": "Amount",
                        "name": "amount"
                    },
                    {
                        "type": "textArea",
                        "label": "Remarks",
                        "name": "remarks"
                    }
                ]
            },
            "formFields": [
                {
                    type: 'select',
                    label: 'Choose Add On',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    fieldIgnore: true,
                    name: 'operation'
                },
                {
                    type: 'button',
                    fieldIgnore: true,
                    label: '+ Add',
                    name: 'add'
                }
            ]
        },        
        {
            "formType": "list",
            "formTitle": "Additional Contacts",
            "formName": "contacts",
            "formFields": [
                {
                    "type": "text",
                    "label": "Person In Charge",
                    "name": "perInCharge"
                },
                {
                    "type": "email",
                    "label": "Email",
                    "name": "email"
                },
                {
                    "type": "number",
                    "label": "Mobile",
                    "name": "mobile"
                },
                {
                    type: 'text',
                    label: 'Company Name',
                    name: 'companyName'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Roles',
                    name: 'roles',
                    required: true
                },
            ]
        },
        {
            "formType": "list",
            "formTitle": "Client PO",
            "formName": "clientPO",
            "formFields": [
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Group',
                    name: 'group',
                    required: true
                },
                {
                    "type": "number",
                    "label": "PO Number",
                    "name": "poNum",
                    required: true
                },
                {
                    "type": "datetime",
                    "label": "PO Date",
                    "name": "poDate",
                    required: true
                },
                {
                    "type": "file",
                    "label": "PO Copy",
                    "name": "poCopy"
                },
                {
                    type: 'text',
                    label: 'Comments',
                    name: 'comment'
                },
            ]
        },
        {
            "formType": "box",
            "formTitle": "Supportive Docs",
            "formFields": [

                {
                    type: 'file',
                    label: 'Vendor Quote',
                    name: 'vendorQuote'
                },
                {
                    type: 'file',
                    label: 'Other Documents',
                    name: 'otherDoc'
                },
                {
                    type: 'richText',
                    label: 'Overall Comments',
                    name: 'overAllComment'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'number',
                    label: 'Total Amount',
                    name: 'totalAmt'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'richText',
                    label: 'Internal Comments',
                    name: 'instructions'
                }
            ]
        }
    ]}