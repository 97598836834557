
import { baseUrl } from "../../constants";
import { getDDAgencyCall, getDDNatureJobCall } from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Acct Group",
    "tableTitle": "Acct Group List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDNatureJobCall());
        },
        list: {
            url: `${baseUrl}/api/settings/group/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "groupName"
            }
        },
        add: `${baseUrl}/api/settings/group`,
        edit: `${baseUrl}/api/settings/group/edit`,
        delete: `${baseUrl}/api/settings/group/remove`
    },
    "tableCol": [
        {
            name: 'Group Code',
            selector: row => row.groupCode,
            sortable: true
        },
        {
            name: 'Group Name',
            selector: row => row.groupName,
            sortable: true
        },
        {
            name: 'Nature of Job',
            selector: row => row.jobNature,
            sortable: true
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Group Name',
                    name: 'groupName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Group Code',
                    name: 'groupCode',
                    required: true
                },
                {
                    "type": "select",
                    "label": "Nature of Job",
                    "name": "jobNature",
                    "options": [
                        "Agency",
                        "Logistics"
                    ]
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                }
            ]
        }
    ],
    "mockData": [{
        agency: 'Agency',
        groupCode: 'fafj000',
        groupName: 'sfsf',
        jobNature: 'Agency',
        description: 'some desc'
    }]
}