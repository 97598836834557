
import { baseUrl } from "../../constants";
import { 
    getDDAgencyCall,
    getDDCountryCall,
    getDDRoleCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Vendor Groups",
    "tableTitle": "Vendor Groups",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDCountryCall());
            dispatch(getDDRoleCall());
        },
        list: {
            url: `${baseUrl}/api/agency/vendor_group/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "clientGrp"
            }
        },
        add: `${baseUrl}/api/agency/vendor_group`,
        edit: `${baseUrl}/api/agency/vendor_group/edit`,
        delete: `${baseUrl}/api/agency/vendor_group/remove`
    },
    "tableCol": [
        {
            name: 'Vendor Group',
            selector: row => row.vendorGrp,
            sortable: true
        },
        {
            name: 'Vendor Group Name',
            selector: row => row.vendorGrpCode,
            sortable: true
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true
        },
        {
            name: 'Billing State / Province',
            selector: row => row.billState,
            sortable: true
        },
        {
            name: 'Postal Code',
            selector: row => row.postalCode,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    "required": true
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Basic Details',
            "formFields": [
                {
                    type: 'text',
                    label: 'Vendor Group',
                    name: 'vendorGrp',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Vendor Group Code',
                    name: 'vendorGrpCode'
                },
                {
                    type: 'select',
                    label: 'Country',
                    name: 'country',
                    "options": [
                        "India",
                        "China"
                    ]
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Billing  Address',
            "formFields": [
                {
                    type: 'text',
                    label: 'Billing Address Line 1',
                    name: 'addr1'
                },
                {
                    type: 'text',
                    label: 'Billing Address Line 2',
                    name: 'addr2'
                },
                {
                    type: 'text',
                    label: 'Billing City',
                    name: 'billCity'
                },
                {
                    type: 'text',
                    label: 'Billing State / Province',
                    name: 'billState'
                },
                {
                    type: 'text',
                    label: 'Postal Code',
                    name: 'postalCode'
                }
            ]
        },
        {
            formType: 'box',
            formTitle: 'Contacts',
            formFields: [
                {
                    type: 'text',
                    label: 'Name',
                    name: 'name',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Email',
                    name: 'email',
                    required: true
                },
                {
                    type: 'number',
                    label: 'Phone',
                    name: 'phone'
                },
                {
                    type: 'select',
                    label: 'Role(s)',
                    name: 'roles',
                    "options": [
                        "Admin",
                        "User"
                    ]
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",
        "vendorGrp": "dbfb",
        "vendorGrpName": "fbcb",
        "country": "India",
        "addr1": "fdg",
        "addr2": "thyh",
        "billCity": "wsdsf",
        "billState": "dbfb",
        "postalCode": "fbfbfbf3535",
        "name": "dbfbfn",
        "email": "ffb",
        "phone": "3535",
        "roles": "Admin"
    }]
}