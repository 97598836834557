import React, {
    useState
} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './styles.css';

function MenuList({
    menuName = 'agency',
    subMenu = [],
    setSelectedSubMenu
}) {
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSubMenu = (subMenuName) => {
        const menuDetails = { menu: menuName, subMenu: subMenuName };
        setSelectedSubMenu(menuDetails);
        handleClose();
    }

    return (
        <div className="menu-wrapper">
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    textTransform: 'none',
                    color: 'white',
                    margin: '0 5px',
                    fontSize: '12px',
                    fontWeight: 600
                }}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {menuName}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {subMenu.map(({ label, name }) => ( name ?
                    <MenuItem onClick={() => {
                        handleSubMenu(name)
                    }}
                        sx={{
                            fontSize: '14px',
                            padding: '2px 15px'
                        }}
                    >{label}</MenuItem>
                : ''))}
            </Menu>
        </div>
    );
}

export default MenuList;
