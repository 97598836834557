
export default {
    "formTitle": "Add Settings",
    "tableTitle": "Invoice Settings List",
    "tableCol": [
        {
            name: 'Invoice Title',
            selector: row => row.invoiceTitle,
            sortable: true
        },
        {
            name: 'Invoice Format',
            selector: row => row.invoiceFormat,
            sortable: true
        },
        {
            name: 'Statutory',
            selector: row => row.statutory,
            sortable: true
        },
        {
            name: 'Payment Information',
            selector: row => row.paymentInformation,
            sortable: true
        },
        {
            name: 'Remarks',
            selector: row => row.remarks,
            sortable: true
        },
        {
            name: 'Overdue Clause',
            selector: row => row.overdueClause,
            sortable: true
        },
        {
            name: 'Account Name',
            selector: row => row.accountName,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Agency',
                    name: 'agency'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Invoice Header",
            "formFields": [
               
                {
                    type: 'text',
                    label: 'Invoice Title',
                    name: 'invoiceTitle',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Invoice Format',
                    name: 'invoiceFormat'
                }
            ]
        },
        {
            "formType": "list",
            "formTitle":"Statutory",
            "formFields": [
                {
                    type: 'text',
                    label: 'Statutory Name',
                    name: 'statutoryName',
                    required: true
                },
                
                {
                    type: 'text',
                    label: 'Statutory Content',
                    name: 'Statutory Content',
                    required: true
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Payment Information",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Payment Information',
                    name: 'paymentInformation',
                    required: true
                },
                {
                    type: 'textArea',
                    label: 'Remarks',
                    name: 'remarks'
                },
                {
                    type: 'textArea',
                    label: 'Overdue Clause',
                    name: 'overdueClause'
                },
                {
                    type: 'text',
                    label: 'Account Name',
                    name: 'accountName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Account Number',
                    name: 'accountNumber',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Bank Name',
                    name: 'bankName',
                    required: true
                },
                {
                    type: 'textArea',
                    label: 'Bank Address',
                    name: 'bankAddress'
                }
            ]
        },
        {
            "formType": "list",
            "formTitle":"Additional Info",
            "formFields": [
                {
                    type: 'text',
                    label: 'Attribute Name',
                    name: 'attributeName',
                    required: true
                },
                
                {
                    type: 'text',
                    label: 'Attribute Value',
                    name: 'attributeValue',
                    required: true
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'richText',
                    label: 'Email Body',
                    name: 'emailBody'
                }
            ]
        }
    ],
    "mockData": [{
        "shipClass": "sfs",
        "description": "dgdg"
    }]
}