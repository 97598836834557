
import { baseUrl } from "../../constants";
import { getDDRegionCall, getDDCcyCall } from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Country",
    "tableTitle": "Country List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDRegionCall());
            dispatch(getDDCcyCall());
        },
        list: {
            url: `${baseUrl}/api/settings/country/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "country"
            }
        },
        add: `${baseUrl}/api/settings/country`,
        edit: `${baseUrl}/api/settings/country/edit`,
        delete: `${baseUrl}/api/settings/country/remove`
    },
    "tableCol": [
        {
            name: 'Region',
            selector: row => row.region,
            sortable: true
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true
        },
        {
            name: 'ISO Code',
            selector: row => row.isoCode,
            sortable: true
        },
        {
            name: 'Currency',
            selector: row => row.currency,
            sortable: true
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    label: 'Region',
                    name: 'region',
                    "options": [
                        "AED",
                        "INR"
                    ],
                    required: true
                },
                {
                    type: 'text',
                    label: 'Country',
                    name: 'country',
                    required: true
                },
                {
                    type: 'text',
                    label: 'ISO Code',
                    name: 'isoCode',
                    required: true
                },
                {
                    "type": "select",
                    "label": "Currency",
                    "name": "currency",
                    "options": [
                        "AED",
                        "INR"
                    ]
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                }
            ]
        }
    ],
    "mockData": [{
        "country": "Gateway",
        "isoCode": "dgdg",
        "currency": "gjgj",
        "description": "sfsgf",
        "region": "sfsf"
    }]
}