import * as React from 'react';
import TextField from '@mui/material/TextField';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './style.css';


export default function TimelineComp({
    handleClose,
    open
}) {
    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'lg'}
        >
          <DialogTitle>Vendor Update</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="update-wrapper"
            style={{
                display: 'flex'
            }}
        >
            <div className="timeline-wrapper"
                style={{
                    display: 'flex'
                }}
            >
                <Timeline position="alternate"
                    sx={{
                        // [`& .${timelineOppositeContentClasses.root}`]: {
                        //     flex: 0.2,
                        // },
                    }}
                >
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                            30 JAN 2024
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                                <DirectionsBoatIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                                2200 hrs
                            </Typography>
                            <Typography>Estimated Arrival: Sun Anchorage</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                        >
                            31 JAN 2024
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary">
                                <DirectionsBoatIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                                2230 hrs
                            </Typography>
                            <Typography>Estimated Berthing: Sunoco Dock #2</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                        >
                            01 FEB 2024
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary" variant="outlined">
                                <DirectionsBoatIcon />
                            </TimelineDot>
                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                                PM hrs
                            </Typography>
                            <Typography>Estimated Departure: Sunoco Dock #2</Typography>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </div>
            <div className="text-wrapper"

            style={{
                display: 'flex',
                flexDirection: 'column'
            }}
            >
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label={'Deliveries to Vessel'}
                    readonly
                    multiline
                    row={2}
                    value={`From Bulbous:
                    I-95 forms (Delivered)`}
                    sx={{width: '400px'}}
                />
                <div style={{ display: 'flex'}}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label={'Vendors'}
                    readonly
                    value={`Nil`}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label={'Off-land from Vessel'}
                    readonly
                    value={`Nil`}
                />
                </div>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label={'CTM: (Delivered)'}
                    readonly
                    multiline
                    row={2}
                    sx={{width: '400px'}}
                    value={`USD 15K (remittance received)
                    Denominations: $100 x 150 = $15,000.`}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label={'Services / Inspections: '}
                    readonly
                    multiline
                    row={3}
                    sx={{width: '600px'}}
                    value={`Doctor Visit AB Tabaosares Fernando (eye has healed with a small scar in the left cornea, HE IS FIT FOR DUTY/ TRAVEL.)`}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label={'Crew Change:'}
                    readonly
                    multiline
                    row={3}
                    sx={{width: '600px'}}
                    value={`On-Signers: (Embarked 31-Jan-2024 @ 2240 LT )
                    Master KRAYCHEV, TODOR Dimitrov:
                    NH844  24JAN SIN - HND 22:20 - 05:55 25JAN
                    NH114  25JAN HND - IAH 10:25 - 07:20 25JAN`}
                />     
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label={'Off-Signers: (Disembarked 01-Feb-2024 @ 1530 LT)'}
                    readonly
                    multiline
                    row={2}
                    sx={{width: '600px'}}
                    value={`Master  BERYOZA, VIKTOR Andriyovych:
                    TK34   01FEB IAH - IST 21:15 - 18:00 02FEB`}
                />
            </div>
        </div>
    </Dialog>
    );
}