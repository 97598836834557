export default {
    "tableTitle": "Vendor Prefunding List",
    "hideForm":true,
    "tableCol": [
        {
            name: 'Ship & Port',
            selector: row => row.shipPort,
            sortable: true
        },
        {
            name: 'Request Date',
            selector: row => row.requestDate,
            sortable: true
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: true
        },
        {
            name: 'Vendor',
            selector: row => row.vendor,
            sortable: true
        },
        {
            name: 'Prefund Currency',
            selector: row => row.prefundCurrency,
            sortable: true
        },
        {
            name: 'Requested Amount',
            selector: row => row.requestedAmount,
            sortable: true
        },
        {
            name: 'Paid Date',
            selector: row => row.paidDate,
            sortable: true
        },
        {
            name: 'Paid Currency',
            selector: row => row.paidCurrency,
            sortable: true
        }
    ],
    "formData": []}