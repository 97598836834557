
export default {
    "formTitle": "Add Assignment",
    "tableTitle": "Job Assignments",
    "tableCol": [
        {
            name: 'Assigned On',
            selector: row => row.assignedOn,
            sortable: true
        },
        {
            name: 'Assignment Type',
            selector: row => row.assignmentType,
            sortable: true
        },
        {
            name: 'Assigned By',
            selector: row => row.assignedBy,
            sortable: true
        },
        {
            name: 'Request Number',
            selector: row => row.requestNumber,
            sortable: true
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: true
        },
        {
            name: 'Primary Agent',
            selector: row => row.primaryAgent,
            sortable: true
        },
        {
            name: 'Backup Agent',
            selector: row => row.backupAgent,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Agency',
                    name: 'agency'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Assignments",
            "formFields": [
                {
                    type: 'date',
                    label: 'Assigned On',
                    name: 'assignedOn',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Assignment Type',
                    name: 'assignmentType',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Assigned By',
                    name: 'assignedBy',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Request Number',
                    name: 'requestNumber',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Appointment',
                    name: 'appointment'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Assignees",
            "formFields": [
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Primary Agent',
                    name: 'primaryAgent',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Backup Agent',
                    name: 'backupAgent'
                }
            ]
        }
    ],
    "mockData": [{
        "shipClass": "sfs",
        "description": "dgdg"
    }]
}