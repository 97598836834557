import React, {
    useState
} from 'react';

import VerticalTabs from './verticalTab';
// import VesselUpdate from '../../components/vendorUpdate/vesselUpdate-1';
// import FullScreenDialog from '../../components/fullScreenDialog/fullScreenDialog';

function DashContent() {
    // const jobDetails = {
    //     "id": 60,
    //     "jobNum": "BNLC-24-00048",
    //     "jobStatus": "Appointed",
    //     "agency": {
    //         "id": 4,
    //         "name": "ABC Pvt Ltdd"
    //     },
    //     "requestDate": "2024-07-24 09:42:39",
    //     "requestType": "Nomination",
    //     "port": "Sam",
    //     "ship": "Titanic",
    //     "client": "Ship Company",
    //     "requestorName": "Sam",
    //     "eta": "2024-07-24 12:00:00",
    //     "requestorEmail": "testshiyam@gmail.com",
    //     "createdBy": "admin",
    //     "app_no": "APP-24-00082",
    //     "app_no_id": 82,
    //     "pda": [
    //         {
    //             "id": 66,
    //             "name": "PDA-24-00038"
    //         }
    //     ]
    // };
    return (
        <div class="bul-container">
            <div class="container-fluid">
                {/* <FullScreenDialog
                open={true}
                setOpen={()=>{}}
                title="Vessel Update"
                >
                    <VesselUpdate {...jobDetails} setShowVendor={()=>{}} /> 
                </FullScreenDialog> */}
                <VerticalTabs />
                {/* <div class="row">
                    <div class="bul-tab-seatch-head">
                <ul class="tabs">
                    <li class="active-tab"><img src="img/pre_ops.png" /><span>Enquiry</span></li>
                      <li><img src="img/ops.png" /><span>Nominations</span></li>
                      <li><img src="img/post_ops.png" /><span>Billing</span></li>
                      <li><img src="img/my_task.png" /><span>My Task</span></li>
                      <li><img src="img/accounts.png" /><span>Invoicing</span></li>
                  </ul>
                  <div class="bul-search-header">
                    <input type="date" class="bul-input" />
                    <input type="search" class="bul-input" placeholder="Search" />
                    <button class="btn">Add Job</button>
                  </div>
                </div>
            </div>
                  <ul class="tabs-content">
                      <li>
                        <div class="childtabs">
                            <button class="tablinks active" onclick="openCity(event, 'alljobs')">All Jobs</button>
                            <button class="tablinks" onclick="openCity(event, 'myjobs')">My Jobs</button>
                            <button class="tablinks" onclick="openCity(event, 'backjobs')">Backup Jobs</button>
                            <button class="tablinks" onclick="openCity(event, 'unassigned')">Un Assigned</button>
                            <button class="tablinks" onclick="openCity(event, 'ackpend')">ACK Pending</button>
                            <button class="tablinks" onclick="openCity(event, 'pdapend')">PDA Pending</button>
                            <button class="tablinks" onclick="openCity(event, 'apppend')">Approval Pending</button>
                            <button class="tablinks" onclick="openCity(event, 'crpend')">Client Reivew Pending</button>
                            <button class="tablinks" onclick="openCity(event, 'pdarej')">PDA Rejected</button>
                            <button class="tablinks" onclick="openCity(event, 'canjob')">Cancelled Jobs</button>
                          </div>
                          
                          <div id="alljobs" class="tabcontent activetab">
                           <figure>
                            <img src="img/ref.png" />
                           </figure>
                          </div>
                          
                          <div id="myjobs" class="tabcontent">
                            
                          </div>
                          
                          <div id="backjobs" class="tabcontent">
                           
                          </div>
                          <div id="unassigned" class="tabcontent">
                          
                          </div>
                          
                          <div id="ackpend" class="tabcontent">
                            
                          </div>
                          <div id="pdapend" class="tabcontent">
                           
                          </div>
                          
                          <div id="apppend" class="tabcontent">
                           
                          </div>
                          <div id="crpend" class="tabcontent">
                            
                          </div>
                          
                          <div id="pdarej" class="tabcontent">
                          </div>
                          <div id="canjob" class="tabcontent">
                           
                          </div>
                          
                        </li>
                    <li>OPS</li>
                    <li>Post Ops</li>
                    <li>My Task</li>
                    <li>Accounts</li>
                  </ul> */}
                
            </div>
        </div>
    );
}

export default DashContent;
