export default {
    "tableTitle": "Prefund Request List",
    "hideForm":true,
    "tableCol": [
        {
            name: 'Ship & Port',
            selector: row => row.shipPort,
            sortable: true
        },
        {
            name: 'Prefund Number',
            selector: row => row.pnumber,
            sortable: true
        },
        {
            name: 'Client',
            selector: row => row.client,
            sortable: true
        },
        {
            name: 'Request Date',
            selector: row => row.requestDate,
            sortable: true
        },
        {
            name: 'Appointment No',
            selector: row => row.appointmentNo,
            sortable: true
        },
        {
            name: 'Prefund Currency',
            selector: row => row.prefundCurrency,
            sortable: true
        },
        {
            name: 'Total Prefunding',
            selector: row => row.totlaPrefunding,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": []}