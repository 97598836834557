
export default {
    "formTitle": "Add Task",
    "tableTitle": "Task List",
    "tableCol": [
        {
            name: 'Task Type',
            selector: row => row.taskType,
            sortable: true
        },
        {
            name: 'Activity',
            selector: row => row.activity,
            sortable: true
        },
        {
            name: 'Due By',
            selector: row => row.dueBy,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: true
        },
        {
            name: 'Assigned To',
            selector: row => row.assignedTo,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }

    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Agency',
                    name: 'agency'
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Task Type',
                    name: 'taskType',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Activity',
                    name: 'activity',
                    required: true
                },
                {
                    type: 'date',
                    label: 'Due By',
                    name: 'dueBy',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Status',
                    name: 'status'
                },
                {
                    type: 'textArea',
                    label: 'Comments',
                    name: 'comments'
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Appointment',
                    name: 'appointment'
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Assigned To',
                    name: 'assignedTo'
                }
            ]
        }
    ],
    "mockData": [{
        "shipClass": "sfs",
        "description": "dgdg"
    }]
}