import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip from '@mui/material/Tooltip';
import HistoryIcon from '@mui/icons-material/History';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmailIcon from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

import { getCardMenu } from '../../utils';
import { setAckCall, pdaPdf, sendPda, cancelApp } from '../../services';
import ViewModal from '../viewModal/viewModal';
import './styles.css';
import ComposeEmail from '../composeMail/composeMail';
import FullScreenDialog from '../fullScreenDialog/fullScreenDialog';
import LetterOfInvite from '../letterOfInvite/letterOfInvite';
import VendorUpdate from '../vendorUpdate/vendorUpdate';
import VesselUpdate from '../vendorUpdate/vesselUpdate-1';

export default function ShipCard({
    setOpen,
    refreshJobCard,
    jobDetails = {}
}) {
    const [expanded, setExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showStatusLoader, setStatusLoader] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [showMail, setShowMail] = useState(false);
    const [showLei, setShowLei] = useState(false);
    const [showVendor, setShowVendor] = useState(false);
    const [showVessel, setShowVessel] = useState(false);
    const [viewProps, setViewProps] = useState({});
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {
        id,
        ship,
        port,
        requestDate,
        requestType,
        jobNum,
        pdaNum,
        pda,
        jobStatus,
        requestorName,
        requestorEmail,
        createdBy,
        client
    } = jobDetails;

    const moreMenuData = getCardMenu(jobStatus, createdBy) || [
        "Assign Hub Agent",
        "Create PDA",
        "Send PDA",
        "Download PDA",
        "View",
        "Update Assignment",
        "Acknowledge Enquiry",
        "LOG",
        "Vendor Update",
        "Vessel Update"
    ]

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{
            maxWidth: 245,
            textAlign: 'left',
            marginLeft: '15px'
        }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon onClick={handleClick} />
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {moreMenuData.map((menuName) => <MenuItem onClick={() => {
                                handleClose();
                                if (menuName === 'Vendor Update') setShowVendor(true);                                
                                else if (menuName === 'Vessel Update') setShowVessel(true);
                                else if (menuName === 'View') {
                                    setOpenView(id);
                                    setViewProps({
                                        payloadVal: { job_id: id },
                                        isEdit: false,
                                        menuName: jobDetails.jobStatus === 'PDA Created' ? 'pda' : 'jobs',
                                        dValue: jobDetails,
                                        isView: true
                                    })
                                }
                                else if (menuName === 'Add PDA') {
                                    setOpenView(id);
                                    setViewProps({
                                        isEdit: true,
                                        menuName: 'pda',
                                        dValue: jobDetails,
                                        closeCb: ()=>{                                            
                                            refreshJobCard();
                                        }
                                    })
                                }
                                else if (menuName === 'Revise PDA') {
                                    setOpenView(id);
                                    setViewProps({
                                        isEdit: true,
                                        menuName: 'pda',
                                        dValue: jobDetails,
                                        closeCb: ()=>{                                            
                                            refreshJobCard();
                                        },
                                        viewName: 'revisePDA'
                                    })
                                }
                                else if (menuName === 'Review PDA') {
                                    setOpenView(id);
                                    setViewProps({
                                        isEdit: true,
                                        menuName: 'clientReviews',
                                        dValue: jobDetails,
                                        closeCb: ()=>{                                            
                                            refreshJobCard();
                                        }
                                    })
                                }
                                else if (menuName === 'Update Daily Status') {
                                    setOpenView(id);
                                    setViewProps({
                                        isEdit: true,
                                        menuName: 'dsr',
                                        dValue: jobDetails,
                                        closeCb: ()=>{                                            
                                            refreshJobCard();
                                        }
                                    })
                                }
                                else if (menuName === 'Download PDA') {
                                    pdaPdf((data) => {
                                        console.log(data);
                                    }, {job_id: id}, jobDetails.pda[0]?.name)
                                }
                                else if (menuName === 'Send PDA') {
                                    setShowMail(true);
                                    // sendPda((data) => {
                                    //     console.log(data);
                                    // }, id)
                                }
                                else if (menuName === 'Letter of Invitation') {
                                    setShowLei(true);
                                    // sendPda((data) => {
                                    //     console.log(data);
                                    // }, id)
                                }
                                else if (menuName === 'Acknowledge') {
                                    setStatusLoader(id);
                                    setAckCall(id, () => {
                                        setStatusLoader(false);
                                        refreshJobCard();
                                    });
                                }
                                else if (menuName === 'Update Appointment') {                                    
                                    setOpenView(id);
                                    setViewProps({
                                        isEdit: true,
                                        isAdd: false,
                                        menuName: 'appointments',
                                        dValue: jobDetails,
                                        closeCb: ()=>{                                            
                                            refreshJobCard();
                                        }
                                    })
                                }
                                else if (menuName === 'Cancel Appointment') {
                                    setStatusLoader(id);
                                    cancelApp(jobDetails.app_no_id, () => {
                                        setStatusLoader(false);
                                        refreshJobCard();
                                    });
                                }
                                else if (menuName === 'Add People') {                                    
                                    setOpenView(id);
                                    setViewProps({
                                        isEdit: true,
                                        isAdd: true,
                                        menuName: 'people',
                                        dValue: jobDetails,
                                        closeCb: ()=>{
                                            refreshJobCard();
                                        }
                                    })
                                }
                                else if (menuName === 'Add Materials') {                                    
                                    setOpenView(id);
                                    setViewProps({
                                        isEdit: true,
                                        isAdd: true,
                                        menuName: 'materials',
                                        dValue: jobDetails,
                                        closeCb: ()=>{                                            
                                            refreshJobCard();
                                        }
                                    })
                                }
                            }}>{menuName}</MenuItem>)}
                        </Menu>
                    </IconButton>
                }
                title={`${ship} @ ${port}`}
                subheader={`Created By :${createdBy} `}
            />
            <CardContent>
                <div class="card-body py-0 servicesclick servicesclick66197" id="7ab7cdcf-1a08-4c62-9415-c6726ee6dc43" isdelete="false"><div class="card-logo-part">
                    <p class="project-sub-title">
                        <span class="status-warn">{`${jobNum} (${pda.length || 0} PDAs)`}</span></p>
                    <p class="project-sub-title">
                        {showStatusLoader ? <CircularProgress size={15} /> : <span class="statusmessageapproved">
                            {/* {jobStatus} */}

                            <Chip
                                label={jobStatus}
                                variant="outlined"
                                size="small"
                                sx={{
                                    backgroundColor: 'white',
                                    paddingTop: '1px',
                                    marginBottom: '1px'
                                }}
                            />
                        </span>}
                    </p>
                </div><div class="project-card-content"><div class="request-info">
                    <div class="mang_tsk">{`${requestorName} (As ${requestType})`}</div>
                    {/* <div>{`Created By :${createdBy} `}</div> */}
                </div></div></div>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <Tooltip title={requestDate}>
                        <IconButton>
                            <CalendarMonthIcon />
                        </IconButton>
                    </Tooltip>
                </IconButton>
                <IconButton aria-label="share">
                    <Tooltip title="FRT - mins">
                        <IconButton>
                            <HistoryIcon />
                        </IconButton>
                    </Tooltip>
                </IconButton>
                <IconButton aria-label="share">
                    <Tooltip title="-% SLA (- hrs)">
                        <IconButton>
                            <TrendingUpIcon />
                        </IconButton>
                    </Tooltip>
                </IconButton>
                <IconButton aria-label="share">
                    <Tooltip title={requestorEmail}>
                        <IconButton>
                            <EmailIcon />
                        </IconButton>
                    </Tooltip>
                </IconButton>
            </CardActions>
            <ViewModal
                open={openView}
                setOpen={setOpenView}
                {...viewProps}
            />
            
            {showMail ? <FullScreenDialog
            open={showMail}
            setOpen={setShowMail}
            title="Compose Mail"
            >
                <ComposeEmail {...jobDetails} setShowMail={setShowMail} /> 
            </FullScreenDialog>: ''}
            
            {showLei ? <FullScreenDialog
            open={showLei}
            setOpen={setShowLei}
            title="Letter of Invitation"
            >
                <LetterOfInvite {...jobDetails} setShowLei={setShowLei} /> 
            </FullScreenDialog>: ''}
            
            {showVendor ? <FullScreenDialog
            open={showVendor}
            setOpen={setShowVendor}
            title="Vendor Update"
            >
                <VendorUpdate {...jobDetails} setShowVendor={setShowVendor} /> 
            </FullScreenDialog>: ''}
            
            {showVessel ? <FullScreenDialog
            open={showVessel}
            setOpen={setShowVessel}
            title="Vessel Update"
            >
                <VesselUpdate {...jobDetails} setShowVessel={setShowVessel} /> 
            </FullScreenDialog>: ''}
        </Card>
    );
}
