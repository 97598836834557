
import { baseUrl } from "../../constants";
import { 
    getDDAgencyCall,
    getDDCountryCall,
    getDDCcyCall,
    getDDRegionCall,
    getDDRoleCall,
    // getDDClientGrpCall,
    getDDPdaCall,
    getDDPayTermCall
    
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Client",
    "tableTitle": "Client List",
    "tableAgency": true,
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDCountryCall());
            dispatch(getDDCcyCall());
            dispatch(getDDRegionCall());
            dispatch(getDDRoleCall());
            dispatch(getDDPdaCall());
            dispatch(getDDPayTermCall());
            // dispatch(getDDClientGrpCall());
        },
        list: {
            url: `${baseUrl}/api/agency/client/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "senderName"
            }
        },
        add: `${baseUrl}/api/agency/client`,
        edit: `${baseUrl}/api/agency/client/edit`,
        delete: `${baseUrl}/api/agency/client/remove`
    },
    "tableCol": [
        {
            name: 'Client Name',
            selector: row => row.clientName,
            sortable: true
        },
        {
            name: 'Primary Email',
            selector: row => row.primaryEmail,
            sortable: true
        },
        {
            name: 'Accounting Email',
            selector: row => row.accountingEmail,
            sortable: true
        },
        {
            name: 'Primary Phone',
            selector: row => row.primaryPhone,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    "required": true
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Client Profile',
            "formFields": [
                {
                    type: 'text',
                    label: 'Client Name',
                    name: 'clientName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Primary Email',
                    name: 'primaryEmail',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Accounting Email',
                    name: 'accountingEmail',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Primary Phone',
                    name: 'primaryPhone'
                },
                {
                    type: 'select',
                    label: 'Payment Schedule',
                    name: 'paySched',
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ]
                },
                // {
                //     type: 'select',
                //     label: 'Client Group',
                //     name: 'clientGrp',
                //     "options": [
                //         "Gateway",
                //         "Quixlab"
                //     ]
                // },
                {
                    type: 'select',
                    label: 'Preferred PDA Currency',
                    name: 'pdaCcy',
                    "options": [
                        "INR",
                        "USD"
                    ]
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Address',
            "formFields": [
                {
                    type: 'select',
                    label: 'Region',
                    name: 'region',
                    "options": [
                        "Asia",
                        "America"
                    ]
                },
                {
                    type: 'select',
                    label: 'Country',
                    name: 'country',
                    "options": [
                        "India",
                        "China"
                    ]
                },
                {
                    type: 'text',
                    label: 'State',
                    name: 'state'
                },
                {
                    type: 'text',
                    label: 'City / County',
                    name: 'city'
                },
                {
                    type: 'text',
                    label: 'Address Line',
                    name: 'addrLine',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Postal Code',
                    name: 'postalCode'
                }
            ]
        },
        {
            formType: 'list',
            formTitle: 'Contacts',
            formName: 'contacts',
            formFields: [
                {
                    type: 'text',
                    label: 'Person',
                    name: 'Person',
                    required: true
                },
                {
                    type: 'number',
                    label: 'Mobile',
                    name: 'mobile'
                },
                {
                    type: 'text',
                    label: 'Email',
                    name: 'email'
                },
                {
                    type: 'number',
                    label: 'Alternate Mobile',
                    name: 'alterMobile'
                },
                {
                    type: 'select',
                    label: 'Roles',
                    name: 'roles',
                    "options": [
                        "Admin",
                        "User"
                    ]
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Bank Details',
            "formFields": [
                {
                    type: 'text',
                    label: 'Bank Name',
                    name: 'bankName'
                },
                {
                    type: 'text',
                    label: 'Account Number',
                    name: 'accNum'
                },
                {
                    type: 'text',
                    label: 'Account Name',
                    name: 'accName'
                },
                {
                    type: 'text',
                    label: 'Routing Number',
                    name: 'routeNum'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Standard Instructions',
                    name: 'instructions'
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",
        "clientName": "dgdg",
        "primaryEmail": "gjgj",
        "accountingEmail": "sfsgf",
        "primaryPhone": "3535",
        "paySched": "Quixlab",
        "clientGrp": "Gateway",
        "pdaCcy": "INR",
        "region": "America",
        "country": "India",
        "state": "dbf",
        "city": "cbcb",
        "addrLine": "dbdcbs",
        "postalCode": "3536",
        "contacts": [
            {
                "Person": "fhfh",
                "mobile": "4646",
                "email": "dgdg",
                "alterMobile": "33545",
                "roles": ""
            }
        ],
        "bankName": "dgdg",
        "accNum": "446",
        "accName": "vbb",
        "routeNum": "5757",
        "instructions": "dbdg"
    }]
}