
export default {
    "formTitle": "Add Expense",
    "tableTitle": "Expense List",
    "tableCol": [
        {
            name: 'Expense Type',
            selector: row => row.expenseType,
            sortable: true
        },
        {
            name: 'Ship',
            selector: row => row.ship,
            sortable: true
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: true
        },
        {
            name: 'Spent By',
            selector: row => row.spentBy,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true
        },
        {
            name: 'Expense Date',
            selector: row => row.expenseDate,
            sortable: true
        },
        {
            name: 'Currency',
            selector: row => row.currency,
            sortable: true
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Agency',
                    name: 'agency'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Classification",
            "formFields": [
                
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Ship',
                    name: 'ship'
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Appointment',
                    name: 'appointment'
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'To be Reimbursed?',
                    name: 'tobeReimbursed',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Status',
                    name: 'status'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Expense Details",
            "formFields": [
                {
                    type: 'date',
                    label: 'Expense Date',
                    name: 'expenseDate',
                    required: true
                },
                 {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Expense Type',
                    name: 'expenseType',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Spend Mode',
                    name: 'spendMode',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Spent By',
                    name: 'spentBy',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Currency',
                    name: 'currency',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Amount',
                    name: 'amount',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Description',
                    name: 'description',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Mapped Activity',
                    name: 'mappedActivity'
                },
                {
                    type: 'file',
                    label: 'Pictures',
                    name: 'pictures'
                },
                {
                    type: 'text',
                    label: 'Documents',
                    name: 'documents'
                },
                {
                    type: 'textArea',
                    label: 'Remarks',
                    name: 'remarks'
                }
            ]
        }
    ],
    "mockData": [{
        "shipClass": "sfs",
        "description": "dgdg"
    }]
}