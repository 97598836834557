
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Add Unit",
    "tableTitle": "Unit List",
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/system/unit/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/system/unit`,
        edit: `${baseUrl}/api/system/unit/edit`,
        delete: `${baseUrl}/api/system/unit/remove`
    },
    "tableCol": [
        {
            name: 'Unit Name',
            selector: row => row.unitName,
            sortable: true
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Unit Name',
                    name: 'unitName',
                    required: true
                }
            ]
        },
        {
            formType: 'flex',
            formFields: [
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                }
            ]
        }
    ],
    "mockData": [{
        "unitName": "sfs",
        "description": "dgdg"
    }]
}