
import { baseUrl } from "../../constants";
import { 
    getDDShipTypeCall,
    getDDShipSubTypeCall,
    getDDFlagCall,
    getDDShipClassCall,
    getDDClientCall,
    getDDAgencyCall,
    getDDAgencyRoleCall,
    getDDShipRoleCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Ship",
    "tableTitle": "Ship List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDShipTypeCall());
            dispatch(getDDShipSubTypeCall());
            dispatch(getDDFlagCall());
            dispatch(getDDShipClassCall());
            dispatch(getDDShipRoleCall());
            dispatch(getDDClientCall());
            dispatch(getDDAgencyCall());
            dispatch(getDDAgencyRoleCall());
        },
        list: {
            url: `${baseUrl}/api/ship/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/ship`,
        edit: `${baseUrl}/api/ship/edit`,
        delete: `${baseUrl}/api/ship/remove`
    },
    "tableCol": [
        {
            name: 'Client',
            selector: row => row.client,
            sortable: true
        },
        {
            name: 'IMO',
            selector: row => row.imo,
            sortable: true
        },
        {
            name: 'Vessel Name',
            selector: row => row.vesselName,
            sortable: true
        },
        {
            name: 'Ship Type',
            selector: row => row.shipType,
            sortable: true
        },
        {
            name: 'Ship Sub Type',
            selector: row => row.shipSubType,
            sortable: true
        },
        {
            name: 'Flag',
            selector: row => row.flag,
            sortable: true
        },
        {
            name: 'Salutation',
            selector: row => row.salutation,
            sortable: true
        },
        {
            name: 'Ship Class',
            selector: row => row.shipClass,
            sortable: true
        },
        {
            name: 'Ship Mail',
            selector: row => row.shipEmail,
            sortable: true
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    label: 'Agency',
                    name: 'agency',
                    "options": [
                        "Admin",
                        "User"
                    ]
                }
            ]
        },
        {
            "formType": "box",
            "formTitle": "Vessel Info",
            "formFields": [
                {
                    type: 'select',
                    label: 'Client',
                    name: 'client',
                    "options": [
                        "Admin",
                        "User"
                    ],
                    required: true
                },
                {
                    type: 'number',
                    label: 'IMO',
                    name: 'imo',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Vessel Name',
                    name: 'vesselName',
                    required: true
                },
                {
                    type: 'select',
                    label: 'Ship Type',
                    name: 'shipType',
                    "options": [
                        "Admin",
                        "User"
                    ],
                    required: true
                },
                {
                    type: 'select',
                    label: 'Ship Sub Type',
                    name: 'shipSubType',
                    "options": [
                        "Admin",
                        "User"
                    ]
                },
                {
                    type: 'text',
                    label: 'Ship Owner',
                    name: 'shipOwner'
                },
                {
                    type: 'select',
                    label: 'Flag',
                    name: 'flag',
                    "options": [
                        "Admin",
                        "User"
                    ]
                },
                {
                    type: 'select',
                    label: 'Ship Class',
                    name: 'shipClass',
                    "options": [
                        "Admin",
                        "User"
                    ]
                },
                {
                    type: 'number',
                    label: 'Built Year',
                    name: 'shipYear'
                },
                {
                    type: 'text',
                    label: 'Salutation',
                    name: 'salutation'
                },
                {
                    type: 'file',
                    label: 'Pictures',
                    name: 'pictures'
                }
            ]
        },
        {
            formType: 'box',
            "formTitle": "Communication",
            formFields: [
                {
                    type: 'text',
                    label: 'Ship Email',
                    name: 'shipEmail'
                },
                {
                    type: 'number',
                    label: 'Phone',
                    name: 'phone'
                },
                {
                    type: 'text',
                    label: 'Tech Group Email',
                    name: 'techGroupEmail'
                },
                {
                    type: 'text',
                    label: 'Manning Group Email',
                    name: 'manningGroupEmail'
                }
            ]
        },
        {
            formType: 'box',
            "formTitle": "Dimensions",
            formFields: [
                {
                    type: 'number',
                    label: 'Length in Meters',
                    name: 'shipLength'
                },
                {
                    type: 'number',
                    label: 'Breadth in Meters',
                    name: 'shipBreath'
                },
                {
                    type: 'number',
                    label: 'Depth in Meters',
                    name: 'shipDepth'
                },
                {
                    type: 'number',
                    label: 'Draft in Meters',
                    name: 'shipDraft'
                },
                {
                    type: 'number',
                    label: 'Gross Tonnage',
                    name: 'shipGrossTon'
                },
                {
                    type: 'number',
                    label: 'Net Tonnage',
                    name: 'shipNetTon'
                },
                {
                    type: 'number',
                    label: 'Dead Weight in Tons',
                    name: 'shipWeight'
                }
            ]
        },
        {
            formType: 'list',
            "formTitle": "Ship Contacts",
            formName: 'shipContact',
            formFields: [
                {
                    type: 'text',
                    label: 'Person In Charge',
                    name: 'inCharge'
                },
                {
                    type: 'email',
                    label: 'Email',
                    name: 'email'
                },
                {
                    type: 'number',
                    label: 'Mobile',
                    name: 'mobile'
                },
                {
                    type: 'select',
                    label: 'PIC Role',
                    name: 'role',
                    "options": [
                        "Admin",
                        "User"
                    ]
                }
            ]
        }
    ],
    "mockData": []
}