import { baseUrl } from "../../constants";
import { 
    getDDRegionCall, getDDCountryCall,
    getDDTimeZCall
 } from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Port",
    "tableTitle": "Port List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDRegionCall());
            dispatch(getDDCountryCall());
            dispatch(getDDTimeZCall());
        },
        list: {
            url: `${baseUrl}/api/settings/port/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "port"
            }
        },
        add: `${baseUrl}/api/settings/port`,
        edit: `${baseUrl}/api/settings/port/edit`,
        delete: `${baseUrl}/api/settings/port/remove`
    },
    "tableCol": [
        {
            name: 'Region',
            selector: row => row.region,
            sortable: true
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => row.port,
            sortable: true
        },
        {
            name: 'Port Code',
            selector: row => row.portCode,
            sortable: true
        },
        {
            name: 'Timezone',
            selector: row => row.timeZone,
            sortable: true
        },
        {
            name: 'Latitude',
            selector: row => row.latitude,
            sortable: true
        },
        {
            name: 'Longitude',
            selector: row => row.longitude,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Region",
                    "name": "region",
                    "options": [
                        "Asia",
                        "Africa"
                    ],
                    "required": true
                },
                {
                    "type": "select",
                    "label": "Country",
                    "name": "country",
                    "options": [
                        "India",
                        "China"
                    ],
                    "required": true
                },
                {
                    type: 'text',
                    label: 'Port',
                    name: 'port',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Port Code',
                    name: 'portCode',
                    required: true
                },
                {
                    "type": "select",
                    "label": "Time Zone",
                    "name": "timeZone",
                    "options": [
                        "IST",
                        "CST"
                    ]
                },
                {
                    type: 'number',
                    label: 'Latitude',
                    name: 'latitude'
                },
                {
                    type: 'number',
                    label: 'Longitude',
                    name: 'longitude'
                },
            ]
        }
    ],
    "mockData": [{
        "region": "Asia",
        "country": "China",
        "port": "sfsf",
        "portCode": "fsf",
        "timeZone": "IST",
        "latitude": "34.343",
        "longitude": "343.353"
    }]
}