
import { baseUrl } from "../../constants";
import { getDDAgencyCall, getDDUnitCall } from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Activity",
    "tableTitle": "Activity List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDUnitCall());
        },
        list: {
            url: `${baseUrl}/api/settings/activity/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/settings/activity`,
        edit: `${baseUrl}/api/settings/activity/edit`,
        delete: `${baseUrl}/api/settings/activity/remove`
    },
    "tableCol": [
        {
            name: 'Activity Code',
            selector: row => row.activityCode,
            sortable: true
        },
        {
            name: 'Activity',
            selector: row => row.activity,
            sortable: true
        },
        {
            name: 'Unit',
            selector: row => row.unit,
            sortable: true
        },
        {
            name: 'Default Unit',
            selector: row => row.defaultUnit,
            sortable: true
        },
        {
            name: 'Shared Activity?',
            selector: row => row.sharedActivity ? 'Yes' : 'No',
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Activity Code',
                    name: 'activityCode'
                },
                {
                    type: 'text',
                    label: 'Activity',
                    name: 'activity',
                    required: true
                },
                {
                    "type": "select",
                    "label": "Unit",
                    "name": "unit",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    required: true
                },
                {
                    "type": "select",
                    "label": "Default Unit",
                    "name": "defaultUnit",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ]
                },
                {
                    type: 'switch',
                    label: 'Shared Activity ?',
                    name: 'sharedActivity'
                },
                {
                    type: 'switch',
                    label: 'Show in DSR?',
                    name: 'showDsr'
                },
                {
                    type: 'switch',
                    label: 'Allows Formula?',
                    name: 'allowsFormula'
                },
                {
                    type: 'switch',
                    label: 'Requires Customer Input?',
                    name: 'RequiresCustInput'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Input Template',
                    name: 'inputTemplate'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Also Known As',
                    name: 'knownAs'
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",// Dropdown
        "activityCode": "dfdf",
        "activity": "gdgd",
        "unit": "Gateway",// Dropdown
        "defaultUnit": "Quixlab",// Dropdown
        "sharedActivity": true,
        "showDsr": true,
        "allowsFormula": true,
        "RequiresCustInput": true,
        "inputTemplate": 'sfsf',
        "description": 'sfsf',
        "knownAs": 'dgdg'
    }]
}