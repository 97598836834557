import React, {
    useState
} from 'react';
import './styles.css';
import Typography from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';

import { setApiAlert } from '../../redux/actions/common';
import { getUserProfile } from '../../redux/selector';
import { baseUrl, headers } from "../../constants";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function ProfilePage() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [isView, setView] = useState(true);
    const [newPwd, setNewPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [pwdAlert, setPwdAlert] = useState(false);
    const proOldData = useSelector(getUserProfile) || {};
    const {
        id,
        firstName,
        lastName,
        userName,
        emailId,
        moblieNo,
        reportTo
    } = proOldData;
    const [proNewData, setProNewData] = useState(proOldData);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePwd = () => {
        if (!newPwd) {
            setPwdAlert("Please fill password");
        }
        else if (newPwd && newPwd === confirmPwd) {
            fetch(`${baseUrl}/api/profile/update`, {
                method: "POST",
                body: JSON.stringify({
                    id,
                    userName,
                    userPwd: newPwd
                }),
                headers
            })
                .then(res => res.json())
                .then(res => {
                    console.log(res);
                    handleClose();
                    dispatch(setApiAlert({
                        msg: res?.message,
                        error: res?.data?.error
                    }));
                })
                .catch(error => {
                    console.error(error);
                })
        }
        else {
            setPwdAlert("Password Mismatch");
        }
    };

    const handleUpdate = () => {
        if(isView){
            setView(!isView);
        }
        else{
            console.log(proNewData);
            fetch(`${baseUrl}/api/profile/user/edit`, {
                method: "POST",
                body: JSON.stringify(proNewData),
                headers
            })
                .then(res => res.json())
                .then(res => {
                    setView(!isView);
                    dispatch(setApiAlert({
                        msg: res?.message,
                        error: res?.data?.error
                    }));
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }

    const setFieldVal = (e, fieldName)=>{
        setProNewData({
            ...proNewData,
            [fieldName]: e.target.value
        });
    };

    return (
        <div class="profile-wrapper">
            <div className='bul-title-heading'>
                <h2> {!isView ? 'Update Profile' : 'Profile'}</h2>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='bul-profile-sec'>
                        <div className='col-md-3'>
                            <div className='bul-profilesec-header'>
                                <h3>Profile Picture</h3>
                            </div>
                            <div className='bul-update-profile'>
                                <div className='profile-pic'></div>
                                {!isView ? <><Button component="label" role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<CloudUploadIcon />} >
                                    Upload file
                                    <VisuallyHiddenInput type="file" />
                                </Button>
                                <p>JPG or PNG no larger than 5 MB</p></>: ''}
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='bul-profilesec-header'>
                                <h3>Account Details</h3>
                            </div>
                            <div className='bul-profile-acc-detail'>
                                <div className='col-md-12'>
                                    <div className={`col-md-4 ${isView ? 'readonly' : ''}`}>
                                        <TextField
                                        onChange={(e)=>{
                                            setFieldVal(e, 'firstName')
                                        }}
                                            inputProps={{ readOnly: isView }}
                                            required label="First Name" defaultValue={firstName} />
                                    </div>
                                    <div className={`col-md-4 ${isView ? 'readonly' : ''}`}>
                                        <TextField
                                        onChange={(e)=>{
                                            setFieldVal(e, 'lastName')
                                        }}
                                            inputProps={{ readOnly: isView }}
                                            label="Last Name" defaultValue={lastName} />
                                    </div>
                                    <div className={`col-md-4 ${isView ? 'readonly' : ''}`}>
                                        <TextField
                                        onChange={(e)=>{
                                            setFieldVal(e, 'userName')
                                        }}
                                            inputProps={{ readOnly: isView }}
                                            required label="User Name" defaultValue={userName} />
                                    </div>
                                    <div className={`col-md-4 ${isView ? 'readonly' : ''}`}>
                                        <TextField
                                        onChange={(e)=>{
                                            setFieldVal(e, 'emailId')
                                        }}
                                            inputProps={{ readOnly: isView }}
                                            required label="Email" defaultValue={emailId} />
                                    </div>

                                    <div className={`col-md-4 ${isView ? 'readonly' : ''}`}>
                                        <TextField
                                        onChange={(e)=>{
                                            setFieldVal(e, 'moblieNo')
                                        }}
                                            inputProps={{ readOnly: isView }}
                                            required label="Mobile Number" defaultValue={moblieNo} />
                                    </div>
                                    <div className={`col-md-4 ${isView ? 'readonly' : ''}`}>
                                        <FormControl fullWidth>
                                            <InputLabel id="simple-select-label">Reporting To</InputLabel>
                                            <Select
                                                onChange={(e)=>{
                                                    setFieldVal(e, 'reportTo')
                                                }}
                                                inputProps={{ readOnly: isView }}
                                                labelId="simple-select-label" id="simple-select" defaultValue={reportTo} >
                                                <MenuItem >abc@yahoo.com</MenuItem>
                                                <MenuItem >efgh@gmailc.om</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='col-md-8'>
                                        <Button variant="contained" onClick={handleUpdate}>
                                            {!isView ? 'Save Changes' : 'Update Profile'}
                                        </Button>
                                        {!isView ? <Button variant="contained" onClick={() => {
                                            setView(true);
                                        }}>
                                            Back
                                        </Button> : ''}
                                        {isView ? <Button className='btn' variant="contained" onClick={handleClickOpen}>
                                            Change Password
                                        </Button> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <React.Fragment>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            component: 'form',
                            onSubmit: (event) => {
                                event.preventDefault();
                                const formData = new FormData(event.currentTarget);
                                const formJson = Object.fromEntries(formData.entries());
                                const email = formJson.email;
                                handleClose();
                            },
                        }}
                    >
                        <DialogTitle>Change Password</DialogTitle>
                        <DialogContent>
                            {pwdAlert ? <Alert
                                onClose={() => {
                                    setPwdAlert(false);
                                }}
                                severity="error"
                                variant="filled"
                                sx={{ width: '100%', marginBottom: '20px' }}
                            >
                                {`${pwdAlert}`}
                            </Alert> : ''}
                            <div className='col-md-6'>
                                <TextField autoFocus required margin="dense" id="password"
                                    name="Passowrd"
                                    label="User Password"
                                    type="password"
                                    value={newPwd}
                                    onChange={(e) => {
                                        setNewPwd(e.target.value);
                                    }}
                                    fullWidth
                                />
                            </div>
                            <div className='col-md-6'>
                                <TextField autoFocus required margin="dense" id="cpassword"
                                    name="Confirm Password"
                                    label="Confirm Password"
                                    type="password"
                                    value={confirmPwd}
                                    onChange={(e) => {
                                        setConfirmPwd(e.target.value);
                                    }}
                                    fullWidth
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                className='btn'
                                onClick={handleChangePwd}
                            >Save</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </div>
        </div>
    );
}

export default ProfilePage;
