import React, { useState } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import './styles.css';
import { vendorUpdateCall } from "../../services";

const boxProps = {
    sx: {
        marginBottom: '20px',
        padding: '20px 0',
        borderRadius: '6px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.33)',
        width: '100%'
    }
};
const titleStyles = {
    textAlign: 'left',
    marginLeft: '20px',
    fontSize: '18px',
    fontWeight: 'bold'
};

const shrinkProps = { InputLabelProps: { shrink: true } };
const stackProps = {
    spacing: { xs: 1, sm: 2, md: 3 },
    sx: {
        margin: '20px'
    },
    direction: "row",
    useFlexGap: true,
    flexWrap: "wrap"
};

const VendorUpdate = ({ setShowVendor }) => {

    const [dsr, setDsr] = useState([
        {date: '', report: ''}
    ]);
    const [agent, setagent] = useState();
    const [vendor, setvendor] = useState();
    const [address, setaddress] = useState();
    const [offLand, setoffLand] = useState();
    const [services, setservices] = useState();
    const [onSign, setonSign] = useState();
    const [offSign, setoffSign] = useState();

    const handleAdd = () => {
        setDsr([...dsr, {date: '', report: ''}])
    };
    const handleDelete = (index) => {
        return () => {
            const updatedVal = [...dsr];
            updatedVal.splice(index, 1);
            setDsr(updatedVal)
        }
    };
    const onChange = (value, fieldName, index) => { 
        const modifiedVal = [...dsr];
        modifiedVal[index] = { ...modifiedVal[index], [fieldName]: value }
        setDsr(modifiedVal);
    };

    return <div className="vendor-wrapper">
        <div className="vendor-field-wrapper" >
            <Box {...boxProps}>
                <Grid
                    justify="space-between"
                    container
                    direction="row"
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <div
                            className="box-title"
                            style={titleStyles}
                        >Status Report</div>
                    </Grid>
                    <Grid item xs={6} justifyContent="flex-end">
                        <Button
                            variant="contained"
                            onClick={handleAdd}
                            sx={{
                                float: 'right',
                                marginRight: '20px'
                            }}
                        >
                            {`+ Add `}
                        </Button>
                    </Grid>
                </Grid>
                {dsr.map((item, index) => <Stack {...stackProps}>   
                    <TextField  type="date" {...shrinkProps} value={item?.date} label="Date"
                        onChange={(event) => { 
                            onChange(event.target.value, 'date', index);
                        }} 
                    /> 
                    <div className="textarea-wrapper">
                        <h4>Report</h4>
                        <textarea value={item?.report} label="Report"
                            onChange={(event) => { 
                                onChange([event.target.value], 'report', index);
                            }} 
                        />
                        <DeleteIcon sx={{
                            color: 'red',
                            marginTop: '15px',
                            cursor: 'pointer',
                            marginLeft: '20px',
                            marginBottom: '20px'
                        }} 
                        onClick={handleDelete(index)}
                        />
                    </div>   
                </Stack>)}
            </Box>
        </div>
        
        <h3>Agent Details</h3>
        <div className="vendor-field-wrapper">
            <TextField value={agent} label="Agent"
                onChange={(event) => { setagent(event.target.value) }} 
            />
            <TextField value={vendor} label="Vendor"
                onChange={(event) => { setvendor(event.target.value) }} 
            />
            <TextField value={address} label="Address"
                onChange={(event) => { setaddress(event.target.value) }} 
            />
            <TextField value={offLand} label="Off Land"
                onChange={(event) => { setoffLand(event.target.value) }} 
            />
        </div>

        <h3>Service Details</h3>
        <div className="vendor-field-wrapper last">
            <TextField value={services} label="Services"
                onChange={(event) => { setservices(event.target.value) }} 
            />
            <TextField value={onSign} label="On Sign"
                onChange={(event) => { setonSign(event.target.value) }} 
            />
            <TextField value={offSign} label="Off Sign"
                onChange={(event) => { setoffSign(event.target.value) }} 
            />
        </div>
        <button className="btn" style={{
            height: '40px',
            background: '#1976d2',
            marginTop: '10px'
        }} onClick={() => {
            const data = {
                dsr,
                agent: [agent],
                vendor: [vendor],
                address,
                offLand,
                services: [services],
                onSign,
                offSign
            };
            // console.log(data);
            vendorUpdateCall(data);
        }}>Generate PDF
        </button>
    </div>;
};

export default VendorUpdate;
