
import { baseUrl } from "../../constants";
import { 
    getDDAlertTypeCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Create Alert Templates",
    "tableTitle": "Alert Templates List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAlertTypeCall());
        },
        list: {
            url: `${baseUrl}/api/system/alert_template/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/system/alert_template`,
        edit: `${baseUrl}/api/system/alert_template/edit`,
        delete: `${baseUrl}/api/system/alert_template/remove`
    },
    "tableCol": [
        {
            name: 'Entity Name',
            selector: row => row.entityName,
            sortable: true
        },
        {
            name: 'Entity Action',
            selector: row => row.entityAction,
            sortable: true
        },
        {
            name: 'Send as Batch?',
            selector: row => row.asBatch,
            sortable: true
        },
        {
            name: 'Alert Type',
            selector: row => row.alertType,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Entity Name',
                    name: 'entityName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Entity Action',
                    name: 'entityAction'
                },
                {
                    type: 'switch',
                    label: 'Send as Batch?',
                    name: 'asBatch'
                },
                {
                    type: 'select',
                    label: 'Alert Type',
                    name: 'alertType',
                    "options": [
                        "Admin",
                        "User"
                    ]
                },
                {
                    type: 'text',
                    label: 'Alert Subject',
                    name: 'alertSubject'
                },
                {
                    type: 'text',
                    label: 'Alert Copy To',
                    name: 'alertCopyTo'
                },
                {
                    type: 'text',
                    label: 'Alert Carbon Copy To',
                    name: 'alertCarbon'
                }
            ]
        },
        {
            formType: 'flex',
            formFields: [
                {
                    type: 'richText',
                    label: 'Alert Content',
                    name: 'alertContent'
                }
            ]
        }
    ],
    "mockData": [{
        "entityName": "ssf",
        "entityAction": "dhgdfh",
        "asBatch": true,
        "alertType": "Admin",
        "alertSubject": "fhh",
        "alertCopyTo": "qfhhj",
        "alertCarbon": "fgfgjh",
        "alertContent": 'sfsf'
    }]
}