
import { baseUrl } from "../../constants";
import { 
    getDDShipTypeCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Sub Ship Type",
    "tableTitle": "Ship Sub Type List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDShipTypeCall());
        },
        list: {
            url: `${baseUrl}/api/ship/shipsubtype/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/ship/shipsubtype`,
        edit: `${baseUrl}/api/ship/shipsubtype/edit`,
        delete: `${baseUrl}/api/ship/shipsubtype/remove`
    },
    "tableCol": [
        {
            name: 'Ship Type',
            selector: row => row.shipType,
            sortable: true
        },
        {
            name: 'Ship Sub Type',
            selector: row => row.shipSubType,
            sortable: true
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    label: 'Ship Type',
                    name: 'shipType',
                    "options": [
                        "Admin",
                        "User"
                    ],
                    required: true
                },
                {
                    type: 'text',
                    label: 'Ship Sub Type',
                    name: 'shipSubType',
                    required: true
                }
            ]
        },
        {
            formType: 'flex',
            formFields: [
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                }
            ]
        }
    ],
    "mockData": [{
        "shipsubtype": "sfs",
        "description": "dgdg",
        "shipSubType": "sdfgd"
    }]
}