
import { baseUrl } from "../../constants";
import { 
    getDDAgencyCall,
    getDDFeatureCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Terms",
    "tableTitle": "Terms List",
    "tableAgency": true,
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDFeatureCall());
        },
        list: {
            url: `${baseUrl}/api/agency/terms/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "clientGrp"
            }
        },
        add: `${baseUrl}/api/agency/terms`,
        edit: `${baseUrl}/api/agency/terms/edit`,
        delete: `${baseUrl}/api/agency/terms/remove`
    },
    "tableCol": [
        {
            name: 'Feature',
            selector: row => row.feature,
            sortable: true
        },
        {
            name: 'Terms',
            selector: row => row.terms,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ]
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Feature",
                    "name": "feature",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    "required": true
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Terms',
                    name: 'terms'
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",
        "feature": "Quixlab",
        "terms": "mnmkn"
    }]
}