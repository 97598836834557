
import { baseUrl } from "../../constants";
import { 
    getDDAgencyCall,
    getDDCountryCall,
    getDDRegionCall,
    getDDClientCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Client Address Book",
    "tableTitle": "Client Address Book List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDCountryCall());
            dispatch(getDDRegionCall());
            dispatch(getDDClientCall());
        },
        list: {
            url: `${baseUrl}/api/agency/address_book/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "clientGrp"
            }
        },
        add: `${baseUrl}/api/agency/address_book`,
        edit: `${baseUrl}/api/agency/address_book/edit`,
        delete: `${baseUrl}/api/agency/address_book/remove`
    },
    "tableCol": [
        {
            name: 'Client Name',
            selector: row => row.clientName,
            sortable: true
        },
        {
            name: 'Address Name',
            selector: row => row.addrName,
            sortable: true
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true
        },
        {
            name: 'Pin',
            selector: row => row.pin,
            sortable: true
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true
        },
        {
            name: 'Address Line 1',
            selector: row => row.addr1,
            sortable: true
        },
        {
            name: 'Address Line 2',
            selector: row => row.addr2,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    "required": true
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Basic Details',
            "formFields": [
                {
                    type: 'select',
                    label: 'Client Name',
                    name: 'clientName',
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ]
                },
                {
                    type: 'text',
                    label: 'Address Name',
                    name: 'addrName'
                },
                {
                    type: 'text',
                    label: 'Company Name',
                    name: 'companyName'
                },
                {
                    type: 'text',
                    label: 'Phone',
                    name: 'phone'
                },
                {
                    type: 'text',
                    label: 'Fax',
                    name: 'fax'
                },
                {
                    type: 'email',
                    label: 'Email',
                    name: 'email'
                },
                {
                    type: 'text',
                    label: 'Tax Info',
                    name: 'taxInfo'
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Address',
            "formFields": [
                {
                    type: 'select',
                    label: 'Region',
                    name: 'region',
                    "options": [
                        "Asia",
                        "America"
                    ]
                },
                {
                    type: 'select',
                    label: 'Country',
                    name: 'country',
                    "options": [
                        "India",
                        "China"
                    ]
                },
                {
                    type: 'text',
                    label: 'Pin',
                    name: 'pin'
                },
                {
                    type: 'text',
                    label: 'City',
                    name: 'city'
                },
                {
                    type: 'text',
                    label: 'Address Line 1',
                    name: 'addr1'
                },
                {
                    type: 'text',
                    label: 'Address Line2',
                    name: 'addr2'
                },
                {
                    type: 'text',
                    label: 'Addtional Info',
                    name: 'addtionalInfo'
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",
        "clientName": "gfgf",
        "addrName": "vhgcg",
        "companyName": "gfcfgcx",
        "phone": "767867",
        "fax": "gcgc",
        "email": "ggf",
        "taxInfo": "gcgc",
        "region": "Asia",
        "country": "China",
        "pin": "gcgc",
        "city": "hvhc",
        "addr1": "fgcgc",
        "addr2": "gvjb",
        "addtionalInfo": "jbjb"
    }]
}