export default {
    "tableTitle": "Approved SRs",
    "tableCol": [
        {
            name: 'SR Number',
            selector: row => row.srNumber,
            sortable: true
        },
        {
            name: 'SR Date',
            selector: row => row.srDate,
            sortable: true
        },
        {
            name: 'Vendor',
            selector: row => row.vendor,
            sortable: true
        },
        {
            name: 'Appointment Number',
            selector: row => row.appointmentNumber,
            sortable: true
        },
        {
            name: 'Delivery Date',
            selector: row => row.deliveryDate,
            sortable: true
        },
        {
            name: 'Net SR Amount',
            selector: row => row.netSrAmount,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": []}