
import { baseUrl } from "../../constants";
import { 
    getDDAgencyCall,
    getDDActivityCall,
    getDDOperationCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Operation Map",
    "tableTitle": "Operation Map List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDActivityCall());
            dispatch(getDDOperationCall());
        },
        list: {
            url: `${baseUrl}/api/settings/map_activity/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/settings/map_activity`,
        edit: `${baseUrl}/api/settings/map_activity/edit`,
        delete: `${baseUrl}/api/settings/map_activity/remove`
    },
    "tableCol": [
        {
            name: 'Operation',
            selector: row => row.operation,
            sortable: true
        },
        {
            name: 'Activity',
            selector: row => row.activity,
            sortable: true
        },
        {
            name: 'Is Required',
            selector: row => row.isRequired,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ]
                },
                {
                    "type": "select",
                    "label": "Operation",
                    "name": "operation",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    required: true
                },
                {
                    "type": "select",
                    "label": "Activity",
                    "name": "activity",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    required: true
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'switch',
                    label: 'Is Required?',
                    name: 'isRequired'
                }
            ]
        }
    ],
    "mockData": [{
        agency: 'Agency',
        operation: 'fafj000',
        activity: 'sfsf',
        isRequired: true
    }]
}