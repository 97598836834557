import * as React from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

function EmailComp({emails, setEmails}) {
  const [focused, setFocused] = React.useState(false);

  return (
      <ReactMultiEmail
        placeholder='Input your email'
        emails={emails}
        onChange={(emails) => {
          setEmails(emails);
        }}
        autoFocus={true}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
  );
}

export default EmailComp;