
import { baseUrl } from "../../constants";
import { getDDAgencyCall } from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Mailbox",
    "tableTitle": "Mailbox List",
    "tableAgency": true,
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
        },
        list: {
            url: `${baseUrl}/api/agency/email/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "senderName"
            }
        },
        add: `${baseUrl}/api/agency/email`,
        edit: `${baseUrl}/api/agency/email/edit`,
        delete: `${baseUrl}/api/agency/email/remove`
    },
    "tableCol": [
        {
            name: 'Sender Display Name',
            selector: row => row.senderName,
            sortable: true
        },
        {
            name: 'Sender Email',
            selector: row => row.senderEmail,
            sortable: true
        },
        {
            name: 'Email Host Name',
            selector: row => row.hostName,
            sortable: true
        },
        {
            name: 'Port Number',
            selector: row => row.portNum,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    "required": true
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Sender Display Name',
                    name: 'senderName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Sender Email',
                    name: 'senderEmail'
                },
                {
                    type: 'text',
                    label: 'Password',
                    name: 'senderPwd',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Email Host Name',
                    name: 'hostName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Port Number',
                    name: 'portNum',
                    required: true
                },
                {
                    type: 'richText',
                    label: 'Email Footer',
                    name: 'emailFooter',
                    required: true
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Quixlab",
        "senderName": "dgdg",
        "senderEmail": "dgdg",
        "senderPwd": "dgdg",
        "hostName": "dgdg",
        "portNum": "dgdg",
        "emailFooter": ""
    }]
}