
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Create MailLogs",
    "tableTitle": "MailLogs List",
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/system/notification/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/system/notification`,
        edit: `${baseUrl}/api/system/notification/edit`,
        delete: `${baseUrl}/api/system/notification/remove`
    },
    "tableCol": [
        {
            name: 'Entity Name',
            selector: row => row.entityName,
            sortable: true
        },
        {
            name: 'Entity ID',
            selector: row => row.entityId,
            sortable: true
        },
        {
            name: 'Mail For',
            selector: row => row.mailFor,
            sortable: true
        },
        {
            name: 'Mail To',
            selector: row => row.mailTo,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Entity Name',
                    name: 'entityName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Entity ID',
                    name: 'entityId'
                },
                {
                    type: 'text',
                    label: 'Mail For',
                    name: 'mailFor'
                },
                {
                    type: 'text',
                    label: 'Mail Subject',
                    name: 'mailSubject'
                },
                {
                    type: 'text',
                    label: 'Mail To',
                    name: 'mailTo'
                }
            ]
        },
        {
            formType: 'flex',
            formFields: [
                {
                    type: 'textArea',
                    label: 'Mail Body',
                    name: 'mailBody'
                }
            ]
        }
    ],
    "mockData": [{
        "entityName": "sfsf",
        "entityId": "sfsf",
        "mailFor": "fdhgfh",
        "mailSubject": "fhfh",
        "mailTo": "ete",
        "mailBody": 'fghfdhg'
    }]
}