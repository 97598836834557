import { baseUrl } from "../../constants";

export default {
    "formTitle": "Add Regions",
    "tableTitle": "Regions List",
    "apiDetails": {
        list: {
            url: `${baseUrl}/api/settings/region/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "region"
            }
        },
        add: `${baseUrl}/api/settings/region`,
        edit: `${baseUrl}/api/settings/region/edit`,
        delete: `${baseUrl}/api/settings/region/remove`
    },
    "tableCol": [
        {
            name: 'Region',
            selector: row => row.region,
            sortable: true
        },
        {
            name: 'Code',
            selector: row => row.code,
            sortable: true
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Region',
                    name: 'region',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Code',
                    name: 'code'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                }
            ]
        }
    ],
    "mockData": [{
        "region": "Gateway",
        "code": "dgdg",
        "description": "gjgj"
    }]
}