
import { baseUrl } from "../../constants";
import { 
    getDDAgencyCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add SLA",
    "tableTitle": "SLA List",
    "tableAgency": true,
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
        },
        list: {
            url: `${baseUrl}/api/agency/sla/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/agency/sla`,
        edit: `${baseUrl}/api/agency/sla/edit`,
        delete: `${baseUrl}/api/agency/sla/remove`
    },
    "tableCol": [
        {
            name: 'FRT Hours',
            selector: row => row.frtHours,
            sortable: true
        },
        {
            name: 'PDA TAT Hours',
            selector: row => row.pdaTatHours,
            sortable: true
        },
        {
            name: 'PDA Review Hours',
            selector: row => row.pdaReviewHours,
            sortable: true
        },
        {
            name: 'Job Assignment Hours',
            selector: row => row.jobAssignHours,
            sortable: true
        },
        {
            name: 'Nomination Assignment Hours',
            selector: row => row.nomiAssignHours,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    "required": true
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Client Profile',
            "formFields": [
                {
                    type: 'number',
                    label: 'FRT Hours',
                    name: 'frtHours',
                    required: true
                },
                {
                    type: 'number',
                    label: 'PDA TAT Hours ',
                    name: 'pdaTatHours',
                    required: true
                },
                {
                    type: 'number',
                    label: 'PDA Review Hours',
                    name: 'pdaReviewHours',
                    required: true
                },
                {
                    type: 'number',
                    label: 'Job Assignment Hours',
                    name: 'jobAssignHours',
                    required: true
                },
                {
                    type: 'number',
                    label: 'Nomination Assignment Hours',
                    name: 'nomiAssignHours',
                    required: true
                },
                {
                    type: 'number',
                    label: 'DS Hours',
                    name: 'dsHours',
                    required: true
                },
                {
                    type: 'number',
                    label: 'DDA Hours',
                    name: 'ddaHours',
                    required: true
                },
                {
                    type: 'number',
                    label: 'FDA Days',
                    name: 'fdaDays',
                    required: true
                },
                {
                    type: 'number',
                    label: 'FDA Variance',
                    name: 'fdaVariance'
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",
        "frtHours": "2424",
        "pdaTatHours": "2424",
        "pdaReviewHours": "23535",
        "jobAssignHours": "3535",
        "nomiAssignHours": "3535",
        "dsHours": "3535",
        "ddaHours": "3535",
        "fdaDays": "3535",
        "fdaVariance": "3535"
    }]
}