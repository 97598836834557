
import { baseUrl } from "../../constants";
import {
    getDDPurposeCall,
    getDDGenderCall,
    getDDArrivalCall,
    getDDAgencyCall,
    getDDOperationCall,
    getDDAppointmentCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add People Info",
    "tableTitle": "People Info",
    "apiDetails": {
        startUp: (dispatch) => {
            [getDDPurposeCall,
                getDDGenderCall,
                getDDArrivalCall,
                getDDAgencyCall,
                getDDOperationCall,
                getDDAppointmentCall
            ].forEach((func) => {
                    dispatch(func());
                });
        },
        list: {
            url: `${baseUrl}/api/service/people/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/service/people`,
        edit: `${baseUrl}/api/service/people/edit`,
        delete: `${baseUrl}/api/service/people/remove`
    },
    "tableCol": [
        {
            name: 'ship',
            selector: row => row.ship,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => row.port,
            sortable: true
        },
        {
            name: 'ETA',
            selector: row => row.eta,
            sortable: true
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: true
        },
        {
            name: 'Purpose',
            selector: row => row.purpose,
            sortable: true
        },
        {
            name: 'Operation',
            selector: row => row.operation,
            sortable: true
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true
        },
        // {
        //     name: 'Rank',
        //     selector: row => row.rank,
        //     sortable: true
        // },
        // {
        //     name: 'Company',
        //     selector: row => row.company,
        //     sortable: true
        // },
        {
            name: 'Arrival Mode',
            selector: row => row.arrivalMode ?? '-',
            sortable: true
        },
        {
            name: 'Arrival Date and Time',
            selector: row => row.arrivalDateandTime,
            sortable: true
        },
        {
            name: 'Arrival At',
            selector: row => (!row.arrivingAt || row.arrivingAt === 'null') ? '-' : row.arrivingAt,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Appointment',
                    name: 'appointment',
                    required: true
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Person Details",
            "formFields": [
               
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Purpose',
                    name: 'purpose',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Operation',
                    name: 'operation',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Name',
                    name: 'name',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Gender',
                    name: 'gender',
                    required: true
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Additional Person Details",
            "formFields": [
                
                 {
                    type: 'text',
                    label: 'Contact Number',
                    name: 'contactNumber'
                },
                {
                    type: 'text',
                    label: 'Alternate Number',
                    name: 'alternateNumber'
                },
                {
                    type: 'email',
                    label: 'Email',
                    name: 'email'
                },
                {
                    type: 'text',
                    label: 'Passport Number',
                    name: 'passportNumber'
                },
                {
                    type: 'date',
                    label: 'Passport Valid Till',
                    name: 'passportValidTill'
                },
                {
                    type: 'switch',
                    label: 'Has Valid Visa?',
                    name: 'hasValidVisa'
                },
                {
                    type: 'date',
                    label: 'Visa Valid Till',
                    name: 'visaValidTill'
                },
                {
                    type: 'textArea',
                    label: 'Remarks',
                    name: 'remarks'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Arrival Details",
            "formFields": [
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Arrival Mode',
                    name: 'arrivalMode'
                },
                 {
                    type: 'date',
                    label: 'Arrival Date and Time',
                    name: 'arrivalDateandTime'
                },
                {
                    type: 'text',
                    label: 'Carrier Name',
                    name: 'carrierName'
                },
                {
                    type: 'text',
                    label: 'Carrier Number',
                    name: 'carrierNumber'
                },
                {
                    type: 'text',
                    label: 'Arriving At',
                    name: 'arrivingAt'
                }
                
            ]
        },
        {
            "formType": "box",
            "formTitle": "Attachments",
            "formFields": [
                
                {
                    "type": "file",
                    "label": "Itenary",
                    "name": "itenary"
                },
                {
                    "type": "file",
                    "label": "Passport",
                    "name": "passport"
                },
                {
                    "type": "file",
                    "label": "Visa",
                    "name": "visa"
                },
                {
                    "type": "file",
                    "label": "CDC",
                    "name": "cdc"
                },
            ]
        },
       
      
        {
            "formType": "box",
            "formTitle": "Added People",
            "formFields": [
                {
                    type: 'richText',
                    label: 'Instructions, if any',
                    name: 'instructions'
                }
            ]
        }
    ],
    "mockData": [{
        "shipClass": "sfs",
        "description": "dgdg"
    }]
}