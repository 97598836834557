import React, {
    useState,
    useRef,
    createRef,
    useEffect
} from 'react';
import Alert from '@mui/material/Alert';
import { TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import '../../index.css';
import { setCookie, getCookie } from '../../utils';
import { baseUrl, headers } from "../../constants";
import { useDispatch } from 'react-redux';
import { setProfileAction } from '../../redux/actions/profile'
import './styles.css';

const LoginPage = ({
    changePage
}) => {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [uName, setUName] = useState('');
    const [pwd, setPwd] = useState('');
    const [apiAlert, setApiAlert] = useState(false);
    const [logLoad, setlogLoad] = useState(false);

    useEffect(() => {
        let cookieVal = getCookie('fluidUser');
        cookieVal = cookieVal ? JSON.parse(cookieVal) : false;
        if (cookieVal) {
            dispatch(setProfileAction(cookieVal));
            setCookie('fluidUser', JSON.stringify(cookieVal), 0.1);
            changePage('dash');
        }
        else{
            setOpen(false);
        }
    }, []);

    const handleLogin = () => {
        setlogLoad(true);
        fetch(`${baseUrl}/api/profile/authentication`, {
            method: "POST",
            body: JSON.stringify({
                "userName": uName,
                "userPwd": pwd
            }),
            headers
        })
            .then(res => res.json())
            .then(res => {
                setlogLoad(false);
                if (res?.data && !res?.data?.error) {
                    dispatch(setProfileAction(res?.data));
                    setCookie('fluidUser', JSON.stringify(res?.data), 0.1);
                    changePage('dash');
                }
                else {
                    setApiAlert(res?.message);
                }
            })
            .catch(error => {
                console.error(error);
            })
    };

    const handleClose = () => {
        setApiAlert(false);
    };

    const handleKeyPress = (e) => {
        if(e.keyCode == 13){
            handleLogin();
         }
    };

    return <div className="bul-login">
        <Backdrop
            sx={{ backgroundColor: "rgb(0 0 0 / 94%)", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className="bul-lg-sec">
            <div className="bul-lg-cnt">
                <div className="bul-lg-forms">
                    <div className="bul-lg-logo">
                        <figure>
                            <img src="img/logo.png" alt="Bulbous" />
                        </figure>
                    </div>
                    <div className="bul-lg-form-group">
                        <h2 className="login-title">Login</h2>
                        {apiAlert ? <Alert
                            onClose={handleClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%', marginBottom: '20px' }}
                        >
                            {`${apiAlert}`}
                        </Alert> : ''}
                        <div className="bul-lg-form-cnt">
                            <TextField
                                label="Username"
                                type="text"
                                error={apiAlert}
                                InputLabelProps={{ shrink: true }}
                                value={uName} onChange={(e) => {
                                    setUName(e?.target?.value);
                                }} />
                            {/* <label>Username</label>
                            <input type="text" value={uName} onChange={(e) => {
                                setUName(e?.target?.value);
                            }} /> */}
                        </div>
                        <div className="bul-lg-form-cnt">
                            <TextField
                                label="Password"
                                type="password"
                                error={apiAlert}
                                InputLabelProps={{ shrink: true }}
                                value={pwd}
                                onChange={(e) => {
                                    setPwd(e?.target?.value);
                                }}
                                onKeyDown={handleKeyPress} />
                            {/* <label>Password</label>
                            <input type="password" value={pwd} onChange={(e) => {
                                setPwd(e?.target?.value);
                            }} /> */}
                        </div>
                        <div className="bul-lg-form-cnt remember-me">
                            <input type="checkbox" id="remember" name="remember" value="remember" />
                            <label for="remember"> Remember Me</label>
                        </div>
                        <div className="bul-lg-form-cnt">
                            <button
                                disabled={logLoad}
                                onClick={handleLogin}
                                type="submit"
                                className="bul-lg-btn-submit">
                                {logLoad ? 'logging in ...' : 'Login'}
                            </button>
                        </div>
                        {/* <div className="bul-lg-form-cnt lb-right">
                            <a href="javascript:;" className="bul-lg-forgot">Forgot Password?</a>
                        </div> */}
                    </div>
                </div>
                <div className="bul-lg-ship-icon">
                    <img src="img/ship.png" alt="Bulbous" />
                </div>
            </div>
        </div>
    </div>;
}

export default LoginPage;