
export const fieldNameMapper = (fieldName, menuName) => {
    switch (fieldName) {
        case 'userRole':
            return 'role';
        case 'role':
            if (menuName === 'agency') {
                return 'agencyRole';
            }
            else if (menuName === 'pda') {
                return 'agencyRole';
            }
            else if (menuName === 'ship') {
                return 'shipRole';
            }
            return 'role';
        case 'roles':
            if (menuName === 'client' || menuName === 'vendor' || menuName === 'pda') {
                return 'agencyRole';
            }
            else {
                return 'roles';
            }
        case 'clientName':
            return 'client';
        case 'pdaNum':
            return 'pdaList';
        case 'reqNum':
            return (menuName === 'dsr' || menuName === 'appointments') ? 'jobNum' : 'reqNum';
        case 'billTo':
            return (menuName === 'pda' || menuName === 'appointments') ? 'client' : 'billTo';
        case 'unit':
            return menuName === 'pda' ? 'actUnit' : 'unit';
        case 'appNo':
            return menuName === 'dsr' ? 'appointNum' : 'appNo';
        case 'cliAddr':
            return menuName === 'appointments' ? 'clientAddr' : 'cliAddr';
        case 'appointment':
            return (menuName === 'people' || menuName === 'materials') ? 'appointNum' : 'appointment';
        case 'dsrShipStatus':
            return 'shipStatus';
        case 'paySched':
            return 'payTerm';
        case 'groupName':
            return 'group';
        case 'defaultUnit':
            return 'unit';
        case 'previousport':
            return 'port';
        case 'status':
            return (menuName === 'appointments' || menuName === 'dsr') ? 'servStatus' : 'status';
        case 'status':
            return (menuName === 'appointments' || menuName === 'dsr') ? 'servStatus' : 'status';
        case 'charterCountry':
        case 'origin':
        case 'previousCountry':
            return 'country';
        default:
            return fieldName;
    }
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const getSelect = (formData, menuName) => {
    const selectFields = [];
    formData[menuName]?.formData.forEach(({ formFields }) => {
        formFields.forEach(({ type, name }) => {
            if (type === 'select') {
                selectFields.push(name);
            }
        })
    });
    return selectFields;
};

export const getCardMenu = (status, createdBy, loginUser) => {
    if (status === 'Created') {
        if (createdBy === loginUser) {
            return [
                "View",
                // "Update Request",
                // "Remove Request",
            ]
        }
        else {
            return [
                "Acknowledge",
                "Add PDA",
                "View",
                // "Update Request",
                // "Remove Request",
            ]
        }
    }
    else if (status === 'Acknowledged') {
        if (createdBy === loginUser) {
            return [
                "View"
            ]
        }
        else {
            return [
                "Add PDA",
                "View"
            ]
        }
    }
    else if (status === 'PDA Created') {
        if (createdBy === loginUser) {
            return [
                "Send PDA",
                "Download PDA",
                "View"
            ]
        }
        else {
            return [
                "Revise PDA",
                "Review PDA",
                "Send PDA",
                "Download PDA",
                "View"
            ]
        }
    }
    else if (status === 'Appointed' || status === 'Cancelled') {
        return [
            "Add People",
            "Add Materials",
            "Update Daily Status",
            // "Current Status Report",
            "Update Appointment",
            "Cancel Appointment",
            "View",
            "Send PDA",
            "Download PDA",
            "Letter of Invitation",
            "Vendor Update",
            "Vessel Update"
        ]
    }
};

export const dataMapper = (data) => {
    const tableData = [];
    const notDD = ['services', 'contacts'];
    data.forEach((item) => {
        const modifiedData = {};
        for (const [key, value] of Object.entries(item)) {
            if (typeof value !== 'object') {
                modifiedData[key] = value;
            }
            else {
                modifiedData[key] = notDD.includes(key) ? value : value?.name;
                modifiedData[`${key}_DD`] = value;
            }
        }
        tableData.push(modifiedData);
    });
    return tableData;
};

export const jobToPdaMap = ({
    port,
    ship,
    services,
    client,
    eta,
    etb,
    etd,
    instructions,
    requestType
}) => ({
    port: port?.id,
    ship: ship?.id,
    services: services.map((item) => {
        return {
            ...item,
            category: item?.category?.id,
            group: item?.group?.id,
            operation: item?.operation?.id,
            operation_DD: item?.operation
        }
    }),
    client: client?.id,
    eta,
    etb,
    etd,
    instructions,
    requestType: requestType?.id
});

export const getTotalAmt = (resultData) => {
    var obj = [];
    for (var property in resultData) {
        if (resultData.hasOwnProperty(property) &&
            property.toString().startsWith("Operation")) {
            obj.push(...(resultData[property] || []));
        }
    }
    var total = 0;
    obj.forEach((data) => {
        total += Number(data.amount);
    });
    return total;
}
