
import { baseUrl } from "../../constants";
import { 
    getDDCountryCall, 
    getDDNatureJobCall,
    getDDAgencyRoleCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Create Agency",
    "tableTitle": "View List Of Agencies",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDCountryCall());
            dispatch(getDDNatureJobCall());
            dispatch(getDDAgencyRoleCall());
        },
        list: {
            url: `${baseUrl}/api/agency/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "agency"
            }
        },
        add: `${baseUrl}/api/agency`,
        edit: `${baseUrl}/api/agency/edit`,
        delete: `${baseUrl}/api/agency/remove`
    },
    "tableCol": [
        {
            name: 'Company Name',
            selector: row => row.companyName,
            sortable: true
        },
        {
            name: 'Business  Email',
            selector: row => row.bEmail,
            sortable: true
        },
        {
            name: 'Business  Phone',
            selector: row => row.bPhone,
            sortable: true
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true
        },
        {
            name: 'State',
            selector: row => row.state,
            sortable: true
        },
        {
            name: 'Zip',
            selector: row => row.zip,
            sortable: true
        },
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "text",
                    "label": "Company Name",
                    "name": "companyName",
                    "required": true
                },
                {
                    "type": "text",
                    "label": "Short Name",
                    "name": "shortName",
                    "required": true
                },
                {
                    "type": "email",
                    "label": "Business Email",
                    "name": "bEmail",
                    "required": true
                },
                {
                    "type": "text",
                    "label": "Business Phone",
                    "name": "bPhone",
                    "required": true
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Nature of Job",
                    "name": "jobNature",
                    "options": [
                        "Agency",
                        "logistics"
                    ],
                    "required": true
                },
                {
                    "type": "text",
                    "label": "Business Website",
                    "name": "bSite"
                },
                {
                    "type": "file",
                    "label": "Organization Logo",
                    "name": "orgLogo",
                    "fieldProps": {
                        "InputLabelProps": {
                            "shrink": true
                        }
                    }
                },
                {
                    "type": "number",
                    "label": "Number of Employees",
                    "name": "empCount"
                }
            ]
        },
        {
            "formType": "box",
            "formTitle": "Address",
            "formFields": [
                {
                    "type": "select",
                    "label": "Country",
                    "name": "country",
                    "options": [
                        "India",
                        "China"
                    ],
                    "required": true
                },
                {
                    "type": "text",
                    "label": "Address Line1",
                    "name": "addr1",
                    "required": true
                },
                {
                    "type": "text",
                    "label": "Address Line1",
                    "name": "addr2"
                },
                {
                    "type": "text",
                    "label": "City",
                    "name": "city"
                },
                {
                    "type": "text",
                    "label": "State",
                    "name": "state"
                },
                {
                    "type": "text",
                    "label": "Zip",
                    "name": "zip"
                }
            ]
        },
        {
            "formType": "list",
            "formTitle": "Contacts",
            "formName": "contacts",
            "formFields": [
                {
                    "type": "text",
                    "label": "Person In Charge",
                    "name": "perInCharge",
                    "required": true
                },
                {
                    "type": "number",
                    "label": "Mobile",
                    "name": "mobile"
                },
                {
                    "type": "email",
                    "label": "Email",
                    "name": "email"
                },
                {
                    "type": "select",
                    "label": "Role",
                    "name": "role",
                    "options": [
                        "Admin",
                        "User"
                    ],
                    "required": true
                }
            ]
        },
        {
            "formType": "list",
            "formTitle": "Additional Markets",
            "formName": "additionalMarket",
            "formFields": [
                {
                    "type": "select",
                    "label": "Add On Country",
                    "name": "country",
                    "options": [
                        "India",
                        "China"
                    ],
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "id": 1,
        "companyName": "Quixlab",
        "shortName": "Quix",
        "bEmail": "admin@quixlab.in",
        "bPhone": "9944336633",
        "jobNature": "software",
        "bSite": "quixlab.i ",
        "orgLogo": null,
        "empCount": "7",
        "country": "India",
        "addr1": "chennai",
        "addr2": "tamil nadu",
        "city": "Chennai",
        "state": "Tamil Nadu",
        "zip": "6000001",
        "contacts": [
            {
                "mobile": "9944336633",
                "email": "admin@quixlab.in",
                "role": 0
            }
        ],
        "additionalMarket": [
            {
                "country": 0
            }
        ]
    },{
        "id": 2,
        "companyName": "Gateway",
        "shortName": "Quix",
        "bEmail": "admin@gateway.in",
        "bPhone": "9944336633",
        "jobNature": "software",
        "bSite": "quixlab.in ",
        "orgLogo": null,
        "empCount": "7",
        "country": "India",
        "addr1": "chennai",
        "addr2": "tamil nadu",
        "city": "Chennai",
        "state": "Tamil Nadu",
        "zip": "6000001",
        "contacts": [
            {
                "mobile": "9944336633",
                "email": "admin@quixlab.in",
                "role": 0
            }
        ],
        "additionalMarket": [
            {
                "country": 0
            }
        ]
    }]
}