import React, {
    useState,
    useEffect
} from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';

const MultiListForm = ({
    formType,
    formTitle,
    addBtnLabel,
    formName,
    defaultFieldValue = [],
    isEdit,
    boxProps,
    stackProps,
    titleStyles,
    createFields,
    formFields,
    opsAdd,
    resultData,
    menuName,
    i
}) => {

    const [fieldList, setFieldList] = useState(defaultFieldValue);
    const addOps = {
        "formType": "flex",
        "formFields": [
            {
                type: 'select',
                label: 'Choose Add On',
                options: [
                    "Type1",
                    "Type2"
                ],
                fieldIgnore: true,
                name: 'operation'
            },
            {
                type: 'button',
                fieldIgnore: true,
                label: '+ Add',
                name: 'add'
            }
        ]
    };

    const handleAdd = () => {
        const fieldObj = {};
        formFields.forEach(({ name }) => {
            fieldObj[name] = ''
        });
        setFieldList([
            ...fieldList,
            fieldObj
        ])
    };
    const handleDelete = (index) => {
        return () => {
            const updatedVal = [...fieldList];
            updatedVal.splice(index, 1);
            setFieldList(updatedVal)
        }
    };
    const renderFields = (index) => {
        const stackPropVal = {
        ...stackProps,
        sx: {
            ...stackProps.sx,
            display: 'flex'
        }
        }
        return <Stack {...stackPropVal}>
            {createFields(formFields, resultData, {
                formType, formName, index
            })}
            {isEdit ? <DeleteIcon sx={{
                color: 'red',
                marginTop: '15px',
                cursor: 'pointer'
            }} 
            onClick={handleDelete(index)}
            /> : ''}
        </Stack>
    };

    useEffect(() => {
        setFieldList(defaultFieldValue);
    }, [defaultFieldValue?.length]);

    return (<Box {...boxProps}>
        <Grid
            justify="space-between"
            container
            direction="row"
            spacing={2}
        >
            <Grid item xs={6}>
                <div
                    className="box-title"
                    style={titleStyles}
                >{menuName === 'pda' ? defaultFieldValue?.[0]?.operation_DD?.name || formTitle : formTitle}</div>
            </Grid>
            <Grid item xs={6} justifyContent="flex-end">
                {isEdit ? <Button
                    variant="contained"
                    onClick={handleAdd}
                    sx={{
                        float: 'right',
                        marginRight: '20px'
                    }}
                >
                    {`+ Add ${opsAdd ? '' :(addBtnLabel || formTitle)}`}
                </Button> : ''}
            </Grid>
        </Grid>
        {fieldList?.map?.((_item, index) => renderFields(index))}
    </Box>);
}

export default MultiListForm;