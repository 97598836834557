
import { baseUrl } from "../../constants";
import { getDDRoleCall, getDDCcyCall } from "../../redux/actions/dropDown";

export default {
    "formTitle": "Register Profile",
    "tableTitle": "List Of User Profiles",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDRoleCall());
            // dispatch(getDDCcyCall());
        },
        list: {
            url: `${baseUrl}/api/profile/user/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "country"
            }
        },
        add: `${baseUrl}/api/profile/user`,
        edit: `${baseUrl}/api/profile/user/edit`,
        delete: `${baseUrl}/api/profile/user/remove`
    },
    "tableCol": [
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true
        },
        {
            name: 'User Name',
            selector: row => row.userName,
            sortable: true
        },
        {
            name: 'Email ID',
            selector: row => row.emailId,
            sortable: true
        },
        {
            name: 'Mobile Number',
            selector: row => row.moblieNo,
            sortable: true
        },
        {
            name: 'User Role',
            selector: row => row.userRole,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'First Name',
                    name: 'firstName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Last Name',
                    name: 'lastName'
                },
                {
                    type: 'file',
                    label: 'Profile Picture',
                    name: 'proPic'
                },
                {
                    type: 'text',
                    label: 'UserName',
                    name: 'userName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'User Password',
                    name: 'userPwd',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Email ID',
                    name: 'emailId',
                    required: true
                },
                {
                    type: 'number',
                    label: 'Mobile Number',
                    name: 'moblieNo',
                    required: true
                },
                {
                    type: 'select',
                    label: 'User Role',
                    name: 'userRole',
                    "options": [
                        "Admin",
                        "User"
                    ]
                },
                {
                    type: 'select',
                    label: 'Reporting To',
                    name: 'reportTo',
                    "options": [
                        "Shiyam",
                        "Kumar"
                    ]
                }
            ]
        },
        {
            formType: 'list',
            formTitle: 'Allowed Devices',
            formName: 'allowedDevice',
            formFields: [
                {
                    type: 'text',
                    label: 'Device Name',
                    name: 'dName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Device ID',
                    name: 'dId'
                },
                {
                    type: 'text',
                    label: 'Notification',
                    name: 'dNoti'
                }
            ]
        }
    ],
    "mockData": [{
        "firstName": "Shiyam",
        "lastName": "Kumar",
        "proPic": "",
        "userName": "Shiyam",
        "userPwd": "userPwd",
        "emailId": "shiyam@quix.in",
        "moblieNo": "325435",
        "userRole": "Admin",
        "reportTo": "Kumar",
        "status": "Active",
        "allowedDevice": [
            {
                "dName": "dfg",
                "dId": "fgg",
                "dNoti": "ghh"
            }
        ]
    },{
        "firstName": "Kumar",
        "lastName": "Shiyam",
        "proPic": "",
        "userName": "Kumar",
        "userPwd": "userPwd",
        "emailId": "kumar@quix.in",
        "moblieNo": "43545",
        "userRole": "Admin",
        "reportTo": "Kumar",
        "status": "Active"
    },{
        "firstName": "Sam",
        "lastName": "Black",
        "proPic": "",
        "userName": "sam",
        "userPwd": "userPwd",
        "emailId": "sam@quix.in",
        "moblieNo": "787878",
        "userRole": "Admin",
        "reportTo": "Kumar",
        "status": "Active"
    }]
}