
import { baseUrl } from "../../constants";
import {
    getDDAgencyCall,
    getDDClientCall,
    getDDShipCall,
    getDDRegionCall,
    getDDCountryCall,
    getDDPortCall,
    getDDReqTypeCall,
    getDDGrpNameCall,
    getDDCategoryCall,
    getDDOperationCall,

} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Request",
    "tableTitle": "Client Requests",
    "apiDetails": {
        startUp: (dispatch) => {
            [getDDAgencyCall,
                getDDClientCall,
                getDDShipCall,
                getDDRegionCall,
                getDDCountryCall,
                getDDPortCall,
                getDDReqTypeCall,
                getDDGrpNameCall,
                getDDCategoryCall,
                getDDOperationCall].forEach((func) => {
                    dispatch(func());
                });
        },
        list: {
            url: `${baseUrl}/api/service/job/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/service/job`,
        edit: `${baseUrl}/api/service/job/edit`,
        delete: `${baseUrl}/api/service/job/remove`
    },
    "tableCol": [
        {
            name: 'Job#',
            selector: row => row.job_no,
            sortable: true
        },
        {
            name: 'Request Date',
            selector: row => row.requestTimestamp,
            sortable: true
        },
        {
            name: 'Request Type',
            selector: row => row.requestType,
            sortable: true
        },
        {
            name: 'Ship',
            selector: row => row.ship,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => row.port,
            sortable: true
        },
        {
            name: 'ETA',
            selector: row => row.eta,
            sortable: true
        },
        {
            name: 'Client Name',
            selector: row => row.client,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.job_status,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "box",
            "formTitle": "Service Request",
            "formFields": [
                {
                    type: 'datetime',
                    label: 'Request Timestamp',
                    name: 'requestTimestamp',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Request Type',
                    name: 'requestType',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Country',
                    name: 'country',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Port',
                    name: 'port',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Agency',
                    name: 'agency',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Client Name',
                    name: 'client',
                    required: true
                }, {
                    type: 'text',
                    label: 'Requestor Name',
                    name: 'requestorName'
                },
                {
                    type: 'email',
                    label: 'Requestor Email',
                    name: 'requestorEmail'
                },
                {
                    type: 'text',
                    label: 'Requestor Phone',
                    name: 'requestorPhone'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle": "Ship Info",
            "formFields": [
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Ship',
                    name: 'ship',
                    required: true
                },
                {
                    type: 'datetime',
                    label: 'ETA',
                    name: 'eta'
                },
                {
                    type: 'datetime',
                    label: 'ETB',
                    name: 'etb'
                },
                {
                    type: 'datetime',
                    label: 'ETD',
                    name: 'etd'
                },
                {
                    type: 'text',
                    label: 'Anchorage / Berth / Terminal',
                    name: 'anchorageBerthTerminal'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Previous Country',
                    name: 'previousCountry'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Previous Port',
                    name: 'previousport'
                }
            ]
        },
        {
            "formType": "list",
            "formTitle": "Services",
            "formName": "services",
            "formFields": [
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Group',
                    name: 'group',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Category',
                    name: 'category',
                    required: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Operation',
                    name: 'operation',
                    required: true
                },
                {
                    "type": "text",
                    "label": "Quantity",
                    "name": "quantity",
                    required: true
                },
                {
                    "type": "textArea",
                    "label": "Comments",
                    "name": "comments"
                },

            ]
        },
        {
            "formType": "list",
            "formTitle": "Contacts",
            "formName": "contacts",
            "formFields": [
                {
                    "type": "text",
                    "label": "Person In Charge",
                    "name": "perInCharge",
                    "required": true
                },
                {
                    "type": "email",
                    "label": "Email",
                    "name": "email"
                },
                {
                    "type": "number",
                    "label": "Mobile",
                    "name": "mobile"
                },

            ]
        },
        {
            "formType": "box",
            "formTitle": "Charter's Agent Details",
            "formFields": [

                {
                    type: 'text',
                    label: 'Agency Name',
                    name: 'agencyName'
                },
                {
                    type: 'text',
                    label: 'Agency Email',
                    name: 'agencyEmail'
                },
                {
                    type: 'text',
                    label: 'Person In Charge',
                    name: 'personInCharge'
                },
                {
                    type: 'number',
                    label: 'Primary Phone',
                    name: 'primaryPhone'
                },
                {
                    type: 'number',
                    label: 'Alternate Phone',
                    name: 'alternatePhone'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Charter Country',
                    name: 'charterCountry'
                },
                {
                    type: 'text',
                    label: 'State or Province',
                    name: 'stateProvince'
                },
                {
                    type: 'text',
                    label: 'Address Line',
                    name: 'addressLine'
                },
                {
                    type: 'number',
                    label: 'Postal Code',
                    name: 'postalCode'
                },
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'file',
                    label: 'Attachment',
                    name: 'attachment'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'richText',
                    label: 'Instructions, if any',
                    name: 'instructions'
                }
            ]
        }
    ],
    "mockData": [{
        "shipClass": "sfs",
        "description": "dgdg"
    }]
}