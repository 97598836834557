
import { baseUrl } from "../../constants";
import { 
    getDDAgencyCall, getDDRoleCall,
    getDDReportEmailCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Create Agency User",
    "tableTitle": "View List Of Agency Users",
    "tableAgency": true,
    "apiDetails": {
        startUp: (dispatch, agencyId) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDRoleCall());
            dispatch(getDDReportEmailCall(agencyId));
        },
        list: {
            url: `${baseUrl}/api/agency/user/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "firstName"
            }
        },
        add: `${baseUrl}/api/agency/user`,
        edit: `${baseUrl}/api/agency/user/edit`,
        delete: `${baseUrl}/api/agency/user/remove`
    },
    "tableCol": [
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        },
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true
        },
        {
            name: 'UserName',
            selector: row => row.userName,
            sortable: true
        },
        {
            name: 'Email ID',
            selector: row => row.emailId,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    "required": true
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'First Name',
                    name: 'firstName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Last Name',
                    name: 'lastName'
                },
                {
                    type: 'file',
                    label: 'Profile Picture',
                    name: 'proPic',
                    fieldProps: {
                        InputLabelProps: { shrink: true }
                    }
                },
                {
                    type: 'text',
                    label: 'UserName',
                    name: 'userName',
                    required: true
                },
                {
                    type: 'password',
                    label: 'User Password',
                    name: 'userPwd',
                    required: true,
                    hideOnEdit: true
                },
                {
                    type: 'email',
                    label: 'Email ID',
                    name: 'emailId',
                    required: true
                },
                {
                    type: 'number',
                    label: 'Mobile Number',
                    name: 'moblieNo',
                    required: true
                },
                {
                    "type": "select",
                    "label": "User Role",
                    "name": "userRole",
                    "options": [
                        "Admin",
                        "User"
                    ],
                    "required": true
                },
                {
                    type: 'select',
                    label: 'Reporting To',
                    name: 'reportTo',
                    options: [
                        'Shiyam',
                        'Tamil',
                        'Guhan',
                        'Kumar'
                    ]
                }
            ]
        },
        {
            formType: 'list',
            formTitle: 'Allowed Devices',
            formName: 'allowedDevice',
            formFields: [
                {
                    type: 'text',
                    label: 'Device Name',
                    name: 'dName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Device ID',
                    name: 'dId'
                },
                {
                    type: 'text',
                    label: 'Notification',
                    name: 'dNoti'
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",
        "firstName": "sdfdsg",
        "lastName": "dfhfh",
        "proPic": null,
        "userName": "fhfh",
        "userPwd": "fhfh",
        "emailId": "gfngfh",
        "moblieNo": "4646",
        "userRole": "Admin",
        "reportTo": "Guhan",
        "allowedDevice": [
            {
                "dName": "dgdg",
                "dId": "3535",
                "dNoti": "sdgsdg"
            }
        ]
    }]
};