/** Profile */
import manageUser from './profile/manageUser.js';

import mock from './mock.js';

/** agency */
import agency from './agency/agency.js';
import agencyUser from './agency/agencyUser.js';
import agencyEmail from './agency/agencyEmail.js';
import clientGrp from './agency/clientGrp.js';
import client from './agency/client.js';
import vendorGrp from './agency/vendorGrp.js';
import vendor from './agency/vendor.js';
import clientAddr from './agency/clientAddr.js';
import terms from './agency/terms.js';
import sla from './agency/sla.js';
import bankInfo from './agency/bankInfo.js';

/** Settings */
import region from './settings/region.js';
import country from './settings/country.js';
import port from './settings/port.js';
import group from './settings/group.js';
import category from './settings/category.js';
import operation from './settings/operation.js';
import operationMap from './settings/operationMap.js';
import currency from './settings/currency.js';
import activity from './settings/activity.js';
// import conversions from './settings/conversion.js';
import numFormat from './settings/numFormat.js';

/** System */
import lookUp from './system/lookUp.js';
import alertTemp from './system/alertTemp.js';
import mailLogs from './system/mailLogs.js';
import unit from './system/unit.js';
import jobNature from './system/jobNature.js';
import cargoUom from './system/cargoUom.js';

/** Ship */
import shipType from './ship/type.js';
import shipSubType from './ship/subType.js';
import shipClass from './ship/class.js';
import ship from './ship/ship.js';
import feedback from './ship/feedback.js';

/** Services  */
import jobs from './services/jobs.js';
import pda from './services/pda.js';
import clientReviews from './services/clientReviews.js';
import assignments from './services/assignments.js';
import appointments from './services/appointments.js';
import vendorsr from './services/vendorsr.js';
import dsr from './services/dsr.js';
import people from './services/people.js';
import materials from './services/materials.js';
import todo from './services/todo.js';

/** Finance */
import prefundRequest from './finance/prefundRequest.js';
import prefundReceipts from './finance/prefundReceipts.js';
import vendorPrefunding from './finance/vendorPrefunding.js';
import payables from './finance/payables.js';
import receivables from './finance/receivables.js';
import expenses from './finance/expenses.js';
import charges from './finance/charges.js';
import invoiceSettings from './finance/invoiceSettings.js';
import directProfit from './finance/directProfit.js';

export default {
    jobs,
    pda,
    clientReviews,
    assignments,
    appointments,
    vendorsr,
    dsr,
    people,
    materials,
    todo,

    prefundRequest,
    prefundReceipts,
    vendorPrefunding,
    payables,
    receivables,
    expenses,
    charges,
    invoiceSettings,
    directProfit,

    shipType,
    shipSubType,
    shipClass,
    ship,
    feedback,

    lookUp,
    alertTemp,
    mailLogs,
    unit,
    jobNature,
    cargoUom,

    region,
    country,
    port,
    group,
    category,
    operation,
    operationMap,
    currency,
    activity,
    // conversions,
    numFormat,
    
    manageUser,

    agency,
    agencyUser,
    agencyEmail,
    clientGrp,
    client,
    vendorGrp,
    vendor,
    clientAddr,
    terms,
    sla,
    bankInfo,

    mock
};
