
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Lookup Data",
    "tableTitle": "List Of Lookup Data",
    // hideForm: true,
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/system/lookup/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/system/lookup`,
        edit: `${baseUrl}/api/system/lookup/edit`,
        delete: `${baseUrl}/api/system/lookup/remove`
    },
    "tableCol": [
        {
            name: 'Entity Name',
            selector: row => row.entityName,
            sortable: true
        },
        {
            name: 'Field Name',
            selector: row => row.fieldName,
            sortable: true
        },
        {
            name: 'Lookup Values',
            selector: row => row.lookupVal,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Entity Name',
                    name: 'entityName'
                },
                {
                    type: 'text',
                    label: 'Field Name',
                    name: 'fieldName'
                },
                {
                    type: 'text',
                    label: 'Look Value',
                    name: 'lookupVal'
                }
            ]
        }
    ],
    "mockData": [{
        entityName: 'VendorContract',
        fieldName: 'chargetype',
        lookupVal: 'Taxes,Discount'
    }]
}