import React, { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Chip from '@mui/material/Chip';
import EmailComp from "../emailComp/emailComp";
import { TextField } from "@mui/material";
import Dropzone from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';

import { composePda } from "../../services";
import './styles.css';

const ComposeMail = ({
    setShowMail,
    requestDate = '',
    jobNum = '',
    id,
    ship,
    port
}) => {
    const msgForamt = `
        <p>Please do not respond to the e-mail address which this mail originates from. For any enquiries please contact accounting@fluid.com<br></p>
        <p>Dear Sir/ Madam,</p>
        <br>
        <p>Your reference: Email dated ${requestDate}</p>
        <p>Our reference: ${jobNum}</p>
        <br>
        <p>Please find attached Proforma for the service requested.</p>
        <br>
        <p><i>Thank you and Best Regards,</i></p>
        <p>Fluid &amp; Logistics Inc.</p>
    `;
    const defaultSub = `${ship} / ${port} / ${requestDate} - (Job Id: ${jobNum})`;
    const [toMail, setToMail] = useState([]);
    const [ccMail, setCcMail] = useState([]);
    const [bccMail, setBccMail] = useState([]);
    const [showCC, setShowCC] = useState(false);
    const [showBCC, setShowBCC] = useState(false);
    const [subject, setSubject] = useState(defaultSub);
    const [msg, setMsg] = useState(msgForamt);
    const [fileList, setFiles] = useState([]);
    // const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const colorList = ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'];
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'color': colorList },
            { 'background': colorList }],
            ['link']
        ],
    }

    const handleDelete = (index) => {
        return () => {
            const updatedVal = [...fileList];
            updatedVal.splice(index, 1);
            setFiles(updatedVal)
        }
    }
    const files = fileList?.map?.((file, index) => (
        <li key={file.path}>
            {file.path}
            <DeleteIcon sx={{
                color: 'red',
                paddingLeft: '5px',
                marginBottom: '-6px',
                cursor: 'pointer'
            }}
                onClick={handleDelete(index)}
            />
        </li>
    ));

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'color', 'background',
        'link'
    ];
    const buttonStyle = {
        height: '40px',
        marginTop: '10px',
        marginRight: '20px',
        border: '1px solid',
        borderColor: '#1976d2',
        background: 'white',
        color: '#1976d2',
        minWidth: 'auto'
    };

    const getTitle = () => {
      return <>
        {`${ship} / ${port} / ${requestDate} `}
        <Chip
          label={jobNum}
          variant="outlined"
          size="small"
          sx={{
            backgroundColor: 'white',
            paddingTop: '1px',
            marginBottom: '1px'
          }}
        />
      </>;
    }

    return <div className="compose-wrapper">
        <div className="compose-header">
            <div>{getTitle()}</div>
            <button className="btn" style={buttonStyle} onClick={() => {
                if (!showCC) { setCcMail([]); }
                setShowCC(!showCC)
            }}>CC
            </button>
            <button className="btn" style={buttonStyle} onClick={() => {
                if (!showBCC) { setBccMail([]); }
                setShowBCC(!showBCC)
            }}>BCC
            </button>
            <button className="btn" style={{
                height: '40px',
                background: '#1976d2',
                marginTop: '10px'
            }} onClick={() => {
                composePda({
                    'attachments[]': fileList,
                    'mailTo':toMail.join(','),
                    subject,
                    message: msg,
                    'ccTo': ccMail.join(','),
                    'bccTo': bccMail.join(','),
                    job_id: id
                }, ()=>{
                    setShowMail(false);
                });
            }}>Send
            </button>
        </div>
        <div className="compose-field-wrapper">
            <h3>To</h3>
            <EmailComp emails={toMail} setEmails={setToMail} />
        </div>
        {showCC ? <div className="compose-field-wrapper">
            <h3>CC</h3>
            <EmailComp emails={ccMail} setEmails={setCcMail} />
        </div> : ''}
        {showBCC ? <div className="compose-field-wrapper">
            <h3>BCC</h3>
            <EmailComp emails={bccMail} setEmails={setBccMail} />
        </div> : ''}
        <div className="compose-field-wrapper">
            <h3>Subject</h3>
            <TextField value={subject} onChange={(event) => { setSubject(event.target.value) }} />
        </div>
        <h3>Message</h3>
        <ReactQuill theme="snow" value={msg} onChange={setMsg}
            modules={modules} formats={formats} />
        <h3>Additional Attachment (If Any)</h3>
        <div>
            <Dropzone onDrop={acceptedFiles => setFiles(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                    <section className="dropzone">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                    </section>
                )}
            </Dropzone>
            {files?.length ? <aside>
                <ul>{files}</ul>
            </aside> : ''}
        </div>
    </div>;
};

export default ComposeMail;
