
export default {
    "formTitle": "Add Direct Profit",
    "tableTitle": "Direct Profit List",
    "tableCol": [
        {
            name: 'Activity',
            selector: row => row.activity,
            sortable: true
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": [
       
        {
            "formType": "flex",
            "formFields": [
                
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Agency',
                    name: 'agency',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Activity',
                    name: 'activity',
                    required: true
                },
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                    
                }
            ]
        }
        
    ],
    "mockData": [{
        "shipClass": "sfs",
        "description": "dgdg"
    }]
}