
import { baseUrl } from "../../constants";
import { 
    getDDAgencyCall,
    getDDAccTypeCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Bank Info",
    "tableTitle": "Bank Info List",
    "tableAgency": true,
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDAccTypeCall());
        },
        list: {
            url: `${baseUrl}/api/agency/bank_info/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "clientGrp"
            }
        },
        add: `${baseUrl}/api/agency/bank_info`,
        edit: `${baseUrl}/api/agency/bank_info/edit`,
        delete: `${baseUrl}/api/agency/bank_info/remove`
    },
    "tableCol": [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Account Name',
            selector: row => row.accName,
            sortable: true
        },
        {
            name: 'Account Number',
            selector: row => row.accNum,
            sortable: true
        },
        {
            name: 'Account Type',
            selector: row => row.accType,
            sortable: true
        },
        {
            name: 'Bank Name',
            selector: row => row.bankName,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ]
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Basic Details',
            "formFields": [
                {
                    type: 'text',
                    label: 'Name',
                    name: 'name',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Account Name',
                    name: 'accName',
                    required: true
                },
                {
                    type: 'number',
                    label: 'Account Number',
                    name: 'accNum',
                    required: true
                },
                {
                    type: 'select',
                    label: 'Account Type',
                    name: 'accType',
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    required: true
                },
                {
                    type: 'text',
                    label: 'Bank Name',
                    name: 'bankName',
                    required: true
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Address',
                    name: 'addr'
                }
            ]
        },
        {
            formType: 'list',
            formTitle: 'Additional Info',
            formName: 'additionalInfo',
            formFields: [
                {
                    type: 'text',
                    label: 'Attribute Name',
                    name: 'attrName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Attribute Value',
                    name: 'attrValue',
                    required: true
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",
        "name": "gdgdg",
        "accName": "dgdg",
        "accNum": "3535",
        "accType": "Gateway",
        "bankName": "sdgdsg",
        "addr": null,
        "additionalInfo": [
            {
                "attrName": "dgdg",
                "attrValue": "dgdggh"
            }
        ]
    }]
}