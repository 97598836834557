
import { baseUrl } from "../../constants";
import {
    getDDClientCall,
    getDDAgencyCall,
    getDDActivityCall,
    getDDActUnitCall,
    getDDbankCall,
    getDDJobNumCall,
    getDDCliAddrCall,
    getDDAgencyRoleCall,
    getDDPdaCall,
    getDDOperationCall,
    getDDServStatusCall,
    getDDServDoerCall,
    getDDCountryCall,
    getDDPortCall,
    getDDShipCall,
    getDDAppointmentCall
} from "../../redux/actions/dropDown";

export default {
    "tableTitle": "Appointments",
    "apiDetails": {
        startUp: (dispatch) => {
            [
                getDDClientCall,
                getDDAgencyCall,
                getDDActivityCall,
                getDDActUnitCall,
                getDDShipCall,
                getDDAppointmentCall,
                getDDbankCall,
                getDDJobNumCall,
                getDDCliAddrCall,
                getDDAgencyRoleCall,
                getDDPdaCall,
                getDDOperationCall,
                getDDServStatusCall,
                getDDServDoerCall,
                getDDCountryCall,
                getDDPortCall
            ].forEach((func) => {
                dispatch(func());
            });
        },
        list: {
            url: `${baseUrl}/api/service/appointment/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/service/appointment`,
        edit: `${baseUrl}/api/service/appointment/edit`,
        delete: `${baseUrl}/api/service/appointment/remove`
    },
    "tableCol": [
        {
            name: 'Ship',
            selector: row => row.ship,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => row.port,
            sortable: true
        },
        {
            name: 'ETA',
            selector: row => row.eta,
            sortable: true
        },
        {
            name: 'Appointment Number',
            selector: row => row.appointNum,
            sortable: true
        },
        {
            name: 'Appointment Date',
            selector: row => row.appointDate,
            sortable: true
        },
        
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true
        },
        {
            name: 'Agency',
            selector: row => row.agency,
            sortable: true
        }
    ],
    "formData": [        
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ]
                },
                {
                    type: 'select',
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    label: 'Appointment Number',
                    name: 'appointNum'
                },
                {
                    type: 'date',
                    label: 'Appointment Date',
                    name: 'appointDate'
                },
                {
                    "type": "select",
                    "label": "Bank Info",
                    "name": "bank",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    "required": true
                },
                {
                    type: 'text',
                    label: 'Voyage No.',
                    name: 'voyageNo'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle": "Request Info",
            "formFields": [
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Ship',
                    name: 'ship',
                    disabled: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Port',
                    name: 'port',
                    disabled: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Country',
                    name: 'country',
                    disabled: true
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Client',
                    name: 'client'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Bill To',
                    name: 'billTo'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Client Address',
                    name: 'cliAddr'
                },
                // {
                //     type: 'select',
                //     label: 'Request Number',
                //     options: [
                //         "Type1",
                //         "Type2"
                //     ],
                //     name: 'jobNum',
                //     disabled: true
                // },
                {
                    type: 'text',
                    label: 'Requestor Name',
                    name: 'reqName',
                    disabled: true
                },
                {
                    type: 'text',
                    label: 'Requestor Email',
                    name: 'reqEmail',
                    disabled: true
                },
                {
                    type: 'text',
                    label: 'Requestor Phone',
                    name: 'reqPhone',
                    disabled: true
                },
                {
                    type: 'text',
                    label: 'Currency',
                    name: 'ccy',
                    disabled: true
                },
                {
                    type: 'text',
                    label: 'Salutation',
                    name: 'salutation'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle": "Ship Details",
            "formFields": [
                {
                    type: 'text',
                    label: 'Berth / Terminal',
                    name: 'berthTerminal'
                },
                {
                    type: 'datetime',
                    label: 'ETA',
                    name: 'eta',
                    required: true
                },
                {
                    type: 'datetime',
                    label: 'ETB',
                    name: 'etb'
                },
                {
                    type: 'datetime',
                    label: 'ETD',
                    name: 'etd'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Previous Country',
                    name: 'previousCountry'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Previous Port',
                    name: 'previousport'
                }
            ]
        },
        {
            "formType": "addList",
            "formIndex": 3,
            "addForm": {
                "formType": "list",
                "formTitle": "Services",
                "formName": "services",
                "opsAdd": true,
                "formFields": [
                    {
                        type: 'select',
                        options: [
                            "Type1",
                            "Type2"
                        ],
                        label: 'Activity',
                        name: 'activity',
                        required: true
                    },
                    {
                        "type": "number",
                        "label": "Quantity",
                        "name": "quantity",
                        required: true
                    },
                    {
                        type: 'select',
                        options: [
                            "Per Call",
                            "Per Vessel"
                        ],
                        label: 'Unit',
                        name: 'unit',
                        required: true
                    },
                    {
                        type: 'select',
                        options: [
                            "Self",
                            "Vendor"
                        ],
                        label: 'Doer',
                        name: 'doer',
                        required: true
                    },
                    // {
                    //     type: 'select',
                    //     options: [
                    //         "Self",
                    //         "Vendor"
                    //     ],
                    //     label: 'Agent',
                    //     name: 'reportTo',
                    //     required: true
                    // },
                    {
                        "type": "number",
                        "label": "USD",
                        "name": "prefCcy",
                        required: true
                    },
                    {
                        "type": "number",
                        "label": "Amount",
                        "name": "amount"
                    },
                    {
                        type: 'select',
                        options: [
                            "Pending",
                            "Done"
                        ],
                        label: 'Status',
                        name: 'status',
                        required: true
                    }
                ]
            },
            "formFields": [
                {
                    type: 'select',
                    label: 'Choose Add On',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    fieldIgnore: true,
                    name: 'operation'
                },
                {
                    type: 'button',
                    fieldIgnore: true,
                    label: '+ Add',
                    name: 'add'
                }
            ]
        },
        {
            "formType": "list",
            "formTitle": "Contacts",
            "formName": "contacts",
            "formFields": [
                {
                    "type": "text",
                    "label": "Person Name",
                    "name": "personName"
                },
                {
                    "type": "email",
                    "label": "Email",
                    "name": "email"
                },
                {
                    "type": "number",
                    "label": "Mobile",
                    "name": "mobile"
                },
                {
                    type: 'text',
                    label: 'Company Name',
                    name: 'companyName'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Roles',
                    name: 'roles',
                    required: true
                },
            ]
        },
        {
            "formType": "list",
            "formTitle": "Client PO",
            "formName": "clientPO",
            "formFields": [
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Group',
                    name: 'group',
                    required: true
                },
                {
                    "type": "number",
                    "label": "PO Number",
                    "name": "poNum",
                    required: true
                },
                {
                    "type": "datetime",
                    "label": "PO Date",
                    "name": "poDate",
                    required: true
                },
                {
                    "type": "file",
                    "label": "PO Copy",
                    "name": "poCopy"
                },
                {
                    type: 'text',
                    label: 'Comments',
                    name: 'comment'
                },
            ]
        },
        {
            "formType": "box",
            "formTitle": "Charter's Agent Details",
            "formFields": [

                {
                    type: 'text',
                    label: 'Agency Name',
                    name: 'agencyName'
                },
                {
                    type: 'text',
                    label: 'Agency Email',
                    name: 'agencyEmail'
                },
                {
                    type: 'text',
                    label: 'Person In Charge',
                    name: 'personInCharge'
                },
                {
                    type: 'number',
                    label: 'Primary Phone',
                    name: 'primaryPhone'
                },
                {
                    type: 'number',
                    label: 'Alternate Phone',
                    name: 'alternatePhone'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Charter Country',
                    name: 'charterCountry'
                },
                {
                    type: 'text',
                    label: 'State or Province',
                    name: 'stateProvince'
                },
                {
                    type: 'text',
                    label: 'Address Line',
                    name: 'addressLine'
                },
                {
                    type: 'number',
                    label: 'Postal Code',
                    name: 'postalCode'
                },
            ]
        },
        {
            "formType": "box",
            "formTitle": "Supportive Docs",
            "formFields": [
                {
                    type: 'file',
                    label: 'PDA Proforma',
                    name: 'pdaProforma'
                },
                {
                    type: 'file',
                    label: 'Vendor Quote',
                    name: 'vendorQuote'
                },
                {
                    type: 'file',
                    label: 'Other Documents',
                    name: 'otherDoc'
                },
                {
                    type: 'richText',
                    label: 'Overall Comments',
                    name: 'overAllComment'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'number',
                    label: 'Total Amount',
                    name: 'totalAmt'
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'richText',
                    label: 'Client Instructions',
                    name: 'instructions'
                }
            ]
        }
    ]}