export default {
    "tableTitle": "Prefund Receipt List",
    "hideForm":true,
    "tableCol": [
        {
            name: 'Ship & Port',
            selector: row => row.shipPort,
            sortable: true
        },
        {
            name: 'Prefund',
            selector: row => row.prefund,
            sortable: true
        },
        {
            name: 'Prefund Currency',
            selector: row => row.prefundCurrency,
            sortable: true
        },
        {
            name: 'Total Prefunding',
            selector: row => row.totalPrefunding,
            sortable: true
        },
        {
            name: 'Received On',
            selector: row => row.receivedOn,
            sortable: true
        },
        {
            name: 'Received From',
            selector: row => row.receivedFrom,
            sortable: true
        },
        {
            name: 'Received Currency',
            selector: row => row.receivedCurrency,
            sortable: true
        }
    ],
    "formData": []}