export default {
    "tableTitle": "Payables List",
    "hideForm":true,
    "tableCol": [
        {
            name: 'Ship & Port',
            selector: row => row.shipPort,
            sortable: true
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: true
        },
        {
            name: 'Vendor',
            selector: row => row.vendor,
            sortable: true
        },
        {
            name: 'Vendor SR',
            selector: row => row.vendorSR,
            sortable: true
        },
        {
            name: 'Vendor Invoice',
            selector: row => row.vendorInvoice,
            sortable: true
        },
        {
            name: 'Currency',
            selector: row => row.currency,
            sortable: true
        },
        {
            name: 'Invoice Date',
            selector: row => row.invoiceDate,
            sortable: true
        },
        {
            name: 'Payable Amount',
            selector: row => row.payableAmount,
            sortable: true
        }
    ],
    "formData": []}