import React, { useState } from "react";
import { TextField } from "@mui/material";

import './styles.css';
import { letterPdf } from "../../services";

const LetterOfInvite = ({
    setShowLei
}) => {
    const [dateVal, setDate] = useState('');
    const [agengy, setagengy] = useState('');
    const [port, setport] = useState('');
    const [arraiveTo, setarraiveTo] = useState('');
    const [letterBy, setletterBy] = useState('');
    
    const [personalDetail, setpersonalDetail] = useState('');
    const [nationality, setnationality] = useState('');
    const [DOB, setDOB] = useState('');
    const [POB, setPOB] = useState('');
    const [flightDetails, setflightDetails] = useState('');
    
    const [passportNo, setpassportNo] = useState('');
    const [passportIssue, setpassportIssue] = useState('');
    const [passportExpiry, setpassportExpiry] = useState('');
    
    const [seamanBook, setseamanBook] = useState('');
    const [seamanIssue, setseamanIssue] = useState('');
    const [seamanExpiry, setseamanExpiry] = useState('');
    
    const [visaType, setvisaType] = useState('');
    const [visaIssue, setvisaIssue] = useState('');
    const [visaExpiry, setvisaExpiry] = useState('');

    const shrinkProps = { InputLabelProps: { shrink: true } };

    return <div className="lei-wrapper">
        <div className="lei-field-wrapper">
            <TextField  type="date" {...shrinkProps} value={dateVal} label="Dated On"
                onChange={(event) => { setDate(event.target.value) }} 
            />
            <TextField value={agengy} label="Agency"
                onChange={(event) => { setagengy(event.target.value) }} 
            />
            <TextField value={port} label="Port"
                onChange={(event) => { setport(event.target.value) }} 
            />
            <TextField value={arraiveTo} label="Arrive To"
                onChange={(event) => { setarraiveTo(event.target.value) }} 
            />
            <TextField value={letterBy} label="Letter By"
                onChange={(event) => { setletterBy(event.target.value) }} 
            />
        </div>
        <h3>Personal Details</h3>
        <div className="lei-field-wrapper">
            <TextField value={personalDetail} label="Person Details"
                onChange={(event) => { setpersonalDetail(event.target.value) }} 
            />
            <TextField value={nationality} label="Nationality"
                onChange={(event) => { setnationality(event.target.value) }} 
            />
            <TextField  type="date" {...shrinkProps} value={DOB} label="Date of Birth"
                onChange={(event) => { setDOB(event.target.value) }} 
            />
            <TextField value={POB} label="Place of Birth"
                onChange={(event) => { setPOB(event.target.value) }} 
            />
            <TextField value={flightDetails} label="Flight Details"
                onChange={(event) => { setflightDetails(event.target.value) }} 
            />
        </div>
        <h3>Passport Details</h3>
        <div className="lei-field-wrapper">
            <TextField value={passportNo} label="Passport No"
                onChange={(event) => { setpassportNo(event.target.value) }} 
            />
            <TextField  type="date" {...shrinkProps} value={passportIssue} label="Passport Issued"
                onChange={(event) => { setpassportIssue(event.target.value) }} 
            />
            <TextField  type="date" {...shrinkProps} value={passportExpiry} label="Passport Expiry"
                onChange={(event) => { setpassportExpiry(event.target.value) }} 
            />
        </div>
        <h3>Seaman Details</h3>
        <div className="lei-field-wrapper">
            <TextField value={seamanBook} label="Seaman Book"
                onChange={(event) => { setseamanBook(event.target.value) }} 
            />
            <TextField  type="date" {...shrinkProps} value={seamanIssue} label="Seaman Issued"
                onChange={(event) => { setseamanIssue(event.target.value) }} 
            />
            <TextField  type="date" {...shrinkProps} value={seamanExpiry} label="Seaman Expiry"
                onChange={(event) => { setseamanExpiry(event.target.value) }} 
            />
        </div>
        <h3>Visa Details</h3>
        <div className="lei-field-wrapper last">
            <TextField value={visaType} label="Visa Type"
                onChange={(event) => { setvisaType(event.target.value) }} 
            />
            <TextField  type="date" {...shrinkProps} value={visaIssue} label="Visa Issued"
                onChange={(event) => { setvisaIssue(event.target.value) }} 
            />
            <TextField  type="date" {...shrinkProps} value={visaExpiry} label="Visa Expiry"
                onChange={(event) => { setvisaExpiry(event.target.value) }} 
            />
        </div>
        <button className="btn" style={{
            height: '40px',
            background: '#1976d2',
            marginTop: '10px'
        }} onClick={() => {
            const data = {
                date: dateVal,
                agengy,
                port,
                arraiveTo,
                personalDetail,
                nationality,
                DOB,
                POB,
                flightDetails,
                passportNo,
                passportIssue,
                passportExpiry,
                seamanBook,
                seamanIssue,
                seamanExpiry,
                visaType,
                visaIssue,
                visaExpiry,
                letterBy
            };
            // console.log(data);
            letterPdf(data);
            // setShowLei(false);
        }}>Generate PDF
        </button>
    </div>;
};

export default LetterOfInvite;
