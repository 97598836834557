
import { baseUrl } from "../../constants";
import {
    getDDAgencyCall,
    getDDMaterialTypeCall,
    getDDMatStatusCall,
    getDDArrivalCall,
    getDDCountryCall,
    getDDAppointmentCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Materials",
    "tableTitle": "Materials List",
    "apiDetails": {
        startUp: (dispatch) => {
            [
                getDDAgencyCall,
                getDDMaterialTypeCall,
                getDDMatStatusCall,
                getDDArrivalCall,
                getDDCountryCall,
                getDDAppointmentCall
            ].forEach((func) => {
                    dispatch(func());
                });
        },
        list: {
            url: `${baseUrl}/api/service/material/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/service/material`,
        edit: `${baseUrl}/api/service/material/edit`,
        delete: `${baseUrl}/api/service/material/remove`
    },
    "tableCol": [
        {
            name: 'Ship',
            selector: row => row.ship,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => row.port,
            sortable: true
        },
        {
            name: 'ETA',
            selector: row => row.eta,
            sortable: true
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: true
        },
        {
            name: 'Material Name',
            selector: row => row.materialName,
            sortable: true
        },
        {
            name: 'Material Type',
            selector: row => row.materialType,
            sortable: true
        },
        {
            name: 'AWB Number',
            selector: row => row.awbReferenceNumber,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.materialStatus,
            sortable: true
        },
        {
            name: 'Arrival Mode',
            selector: row => row.arrivalMode,
            sortable: true
        }

    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Appointment',
                    name: 'appointment',
                    required: true
                }
            ]
        },
        {
            "formType": "box",
            "formTitle":"Material Details",
            "formFields": [
                {
                    type: 'text',
                    label: 'Material Name',
                    name: 'materialName',
                    required: true
                },
                {
                    type: 'text',
                    label: 'AWB / Reference Number',
                    name: 'awbReferenceNumber',
                    required: true
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Material Type',
                    name: 'materialType'
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Origin',
                    name: 'origin'
                },
                {
                    type: 'textArea',
                    label: 'Weight / Dimensions',
                    name: 'weightDimensions'
                },
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                },
                {
                    type: 'file',
                    label: 'AWB',
                    name: 'awb'
                },
                {
                    type: 'switch',
                    label: 'Warehouse Required?',
                    name: 'warehouseRequired'
                },
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Status',
                    name: 'materialStatus'
                },
            ]
        },
        {
            "formType": "box",
            "formTitle":"Arrival Details",
            "formFields": [
                {
                    type: 'select',
                    options:[
                        "Type1",
                        "Type2"
                    ],
                    label: 'Arrival Mode',
                    name: 'arrivalMode'
                },
                {
                    type: 'date',
                    label: 'Arrival Date and Time',
                    name: 'arrivalDateandTime'
                },
                {
                    type: 'text',
                    label: 'Carrier Name',
                    name: 'carrierName'
                },
                {
                    type: 'text',
                    label: 'Carrier Number',
                    name: 'carrierNumber'
                },
                
                {
                    type: 'text',
                    label: 'Arriving At',
                    name: 'arrivingAt'
                }
                
            ]
        }
    ],
    "mockData": [{
        "shipClass": "sfs",
        "description": "dgdg"
    }]
}