
import { baseUrl } from "../../constants";
import {
    getDDReportTypeCall,
    getDDShipStatusCall,
    getDDMaterialCall,
    getDDPeopleCall,
    getDDServStatusCall,
    getDDActivityCall,
    getDDAgencyCall,
    getDDClientCall,
    getDDShipCall,
    getDDJobNumCall,
    getDDRegionCall,
    getDDCountryCall,
    getDDPortCall,
    getDDAppointmentCall
} from "../../redux/actions/dropDown";

export default {
    "tableTitle": " Daily Status List",
    "apiDetails": {
        startUp: (dispatch) => {
            [
                getDDReportTypeCall,
                getDDAppointmentCall,
                getDDShipStatusCall,
                getDDMaterialCall,
                getDDPeopleCall,
                getDDServStatusCall,
                getDDActivityCall,
                getDDAgencyCall,
                getDDClientCall,
                getDDJobNumCall,
                getDDShipCall,
                getDDRegionCall,
                getDDCountryCall,
                getDDPortCall,
            ].forEach((func) => {
                dispatch(func());
            });
        },
        list: {
            url: `${baseUrl}/api/service/dsr/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/service/dsr`,
        edit: `${baseUrl}/api/service/dsr/edit`,
        delete: `${baseUrl}/api/service/dsr/remove`
    },
    "tableCol": [
        {
            name: 'Appointment No',
            selector: row => row.appNo_DD?.name,
            sortable: true
        },
        {
            name: 'Status On',
            selector: row => row.statusOn,
            sortable: true
        },
        {
            name: 'Report Type',
            selector: row => row.reportType,
            sortable: true
        },
        {
            name: 'Ship',
            selector: row => row.ship,
            sortable: true
        },
        {
            name: 'ETA',
            selector: row => row.eta,
            sortable: true
        },
        {
            name: 'ETB',
            selector: row => row.etb,
            sortable: true
        },
        {
            name: 'ETD',
            selector: row => row.etd,
            sortable: true
        },
        {
            name: 'Ship Status',
            selector: row => row.dsrShipStatus_DD?.name,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "box",
            "formTitle": "Agent Comments",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Technical',
                    name: 'technical',
                    width: 'full'
                },
                {
                    type: 'textArea',
                    label: 'Crewing',
                    name: 'crewing',
                    width: 'full'
                },
                {
                    type: 'textArea',
                    label: 'CTM',
                    name: 'ctm',
                    width: 'full'
                },
                {
                    type: 'textArea',
                    label: 'General',
                    name: 'general',
                    width: 'full'
                },
                {
                    type: 'file',
                    label: 'Attachments',
                    name: 'attachments'
                }
            ]
        },
        {
            "formType": "box",
            "formTitle": "Appointment Info",
            "formFields": [
                {
                    type: 'select',
                    label: 'Ship',
                    name: 'ship',
                    disabled: true
                },
                {
                    type: 'select',
                    label: 'Agency',
                    name: 'agency',
                    disabled: true
                },
                {
                    type: 'select',
                    label: 'Appointment No',
                    name: 'appNo',
                    disabled: true
                },
                {
                    type: 'select',
                    label: 'Client',
                    name: 'client',
                    disabled: true
                },
                {
                    type: 'select',
                    label: 'Request Number',
                    name: 'reqNum',
                    disabled: true
                },
                {
                    type: 'select',
                    label: 'Country',
                    name: 'country',
                    disabled: true
                },
                {
                    type: 'select',
                    label: 'Port',
                    name: 'port',
                    disabled: true
                }
            ]
        },
        {
            "formType": "box",
            "formTitle": "Ship Details",
            "formFields": [
                {
                    type: 'date',
                    label: 'Status On',
                    name: 'statusOn'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Report Type',
                    name: 'reportType'
                },
                {
                    type: 'text',
                    label: 'Berth / Terminal',
                    name: 'berthTerminal'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Ship Status',
                    name: 'dsrShipStatus'
                },
                {
                    type: 'datetime',
                    label: 'ETA',
                    name: 'eta',
                    required: true
                },
                {
                    type: 'datetime',
                    label: 'ETB',
                    name: 'etb'
                },
                {
                    type: 'datetime',
                    label: 'ETD',
                    name: 'etd'
                }
            ]
        },
        {
            "formType": "list",
            "formTitle": "Status Update",
            "formName": "services",
            "addBtnLabel": "Services",
            "formFields": [
                {
                    type: 'select',
                    label: 'Activity',
                    name: 'activity',
                    disabled: true
                },
                {
                    "type": "number",
                    "label": "Quantity",
                    "name": "quantity",
                    disabled: true
                },
                {
                    type: 'select',
                    options: [
                        "Per Call",
                        "Per Vessel"
                    ],
                    label: 'Materials',
                    name: 'materials'
                },
                {
                    type: 'select',
                    options: [
                        "Self",
                        "Vendor"
                    ],
                    label: 'People',
                    name: 'people'
                },
                {
                    type: 'select',
                    options: [
                        "Pending",
                        "Done"
                    ],
                    label: 'Status',
                    name: 'status',
                    required: true
                },
                {
                    "type": "date",
                    "label": "On",
                    "name": "actDate"
                },
                {
                    "type": "textArea",
                    "label": "Arrangements",
                    "name": "arrangements"
                },
                {
                    "type": "textArea",
                    "label": "Comments",
                    "name": "comments"
                }
            ]
        },
        {
            "formType": "list",
            "formTitle": "Contacts",
            "formName": "shipContacts",
            "formFields": [
                {
                    "type": "text",
                    "label": "Person Name",
                    "name": "personName"
                },
                {
                    "type": "email",
                    "label": "Email",
                    "name": "email"
                },
                {
                    "type": "number",
                    "label": "Mobile",
                    "name": "mobile"
                },
                {
                    type: 'text',
                    label: 'Company Name',
                    name: 'companyName'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Roles',
                    name: 'roles',
                    required: true
                },
            ]
        },
    ]}