
import { baseUrl } from "../../constants";
import { 
    getDDSerTypeCall,
    getDDBaseDateCall,
    getDDAgencyCall,
    getDDNumFmtCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Number Format",
    "tableTitle": "Number Format List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDSerTypeCall());
            dispatch(getDDBaseDateCall());
            dispatch(getDDAgencyCall());
            dispatch(getDDNumFmtCall());
        },
        list: {
            url: `${baseUrl}/api/settings/number_format/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/settings/number_format`,
        edit: `${baseUrl}/api/settings/number_format/edit`,
        delete: `${baseUrl}/api/settings/number_format/remove`
    },
    "tableCol": [
        {
            name: 'Series Type',
            selector: row => row.seriesType,
            sortable: true
        },
        {
            name: 'Prefix',
            selector: row => row.prefix,
            sortable: true
        },
        {
            name: 'Number Format',
            selector: row => row.numFormat,
            sortable: true
        },
        {
            name: 'Suffix',
            selector: row => row.suffix,
            sortable: true
        },
        {
            name: 'Effective From',
            selector: row => row.effectiveFrom,
            sortable: true
        },
        {
            name: 'Effective To',
            selector: row => row.effectiveTo,
            sortable: true
        },
        {
            name: 'Starting Sequence',
            selector: row => row.seqStart,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    required: true
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Numbering Sequence',
            "formFields": [
                {
                    "type": "select",
                    "label": "Series Type",
                    "name": "seriesType",
                    "options": [
                        "INR",
                        "USD"
                    ],
                    required: true
                },
                {
                    type: 'text',
                    label: 'Prefix',
                    name: 'prefix'
                },
                {
                    "type": "select",
                    "label": "Number Format",
                    "name": "numFormat",
                    "options": [
                        "999",
                        "9999"
                    ],
                    required: true
                },
                {
                    type: 'text',
                    label: 'Suffix',
                    name: 'suffix'
                },
                {
                    type: 'date',
                    label: 'Effective From',
                    name: 'effectiveFrom',
                    required: true
                },
                {
                    type: 'date',
                    label: 'Effective To',
                    name: 'effectiveTo',
                    required: true
                },
                {
                    type: 'select',
                    label: 'Base Date',
                    name: 'baseDate',
                    "options": [
                        "Arrival Date",
                        "Depature Date",
                        "Invoice Date"
                    ]
                }
            ],
            required: true
        },
        {
            "formType": "box",
            "formTitle": "Current Values",
            "formFields": [
                {
                    "type": "text",
                    "label": "Starting Sequence",
                    "name": "seqStart",
                    required: true
                },
                {
                    "type": "text",
                    "label": "Current Value",
                    "name": "currentValue",
                    required: true
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",// Dropdown
        "seriesType": "INR",// Dropdown
        "prefix": "sfsf",
        "numFormat": "999",// Dropdown
        "suffix": "sfsf",
        "effectiveFrom": "20/12/23", // date
        "effectiveTo": "20/1/24",// date
        "baseDate": "Arrival Date"// Dropdown
    }]
}