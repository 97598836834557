import { baseUrl, headers } from "../../constants";


const getCall = (url, body, successCb) => {
    return dispatch => {
        fetch(url, {
            method: "POST",
            body: body ? JSON.stringify(body) : null,
            headers
        })
        .then(res => res.json())
        .then(res => {
            dispatch(successCb(res.data));
        })
        .catch(error => {
            console.error(error);
        })
    }
};

/** Region */
export const setDDRegionAction = (payload) => {
    return {
        type: "SET_DD_REGION",
        payload
    }
};
export const getDDRegionCall = (body) => {
    return getCall(`${baseUrl}/api/listing/region`, body, setDDRegionAction);
};

/** Currency */
export const setDDCcyAction = (payload) => {
    return {
        type: "SET_DD_CCY",
        payload
    }
};
export const getDDCcyCall = (body) => {
    return getCall(`${baseUrl}/api/listing/currency`, body, setDDCcyAction);
};

/** Country */
export const setDDCountryAction = (payload) => {
    return {
        type: "SET_DD_COUNTRY",
        payload
    }
};
export const getDDCountryCall = (body = {}) => {
    return getCall(`${baseUrl}/api/listing/country`, {
        // region: 0,
        ...body
    }, setDDCountryAction);
};

/** Port */
export const setDDPortAction = (payload) => {
    return {
        type: "SET_DD_PORT",
        payload
    }
};
export const getDDPortCall = (body = {}) => {
    return getCall(`${baseUrl}/api/listing/port`, {
        // region: 0,
        ...body
    }, setDDPortAction);
};

/** Agency */
export const setDDAgencyAction = (payload) => {
    return {
        type: "SET_DD_AGENCY",
        payload
    }
};
export const getDDAgencyCall = (body) => {
    return getCall(`${baseUrl}/api/listing/agency`, body, setDDAgencyAction);
};

/** Role */
export const setDDRoleAction = (payload) => {
    return {
        type: "SET_DD_ROLE",
        payload
    }
};
export const getDDRoleCall = (body) => {
    return getCall(`${baseUrl}/api/listing/role`, body, setDDRoleAction);
};

/** Job Nature */
export const setDDNatureJobAction = (payload) => {
    return {
        type: "SET_DD_N_JOB",
        payload
    }
};
export const getDDNatureJobCall = (body) => {
    return getCall(`${baseUrl}/api/listing/no-of-job`, body, setDDNatureJobAction);
};

/** Unit */
export const setDDUnitAction = (payload) => {
    return {
        type: "SET_DD_UNIT",
        payload
    }
};
export const getDDUnitCall = (body) => {
    return getCall(`${baseUrl}/api/listing/unit`, body, setDDUnitAction);
};

/** Client Group */
export const setDDClientGrpAction = (payload) => {
    return {
        type: "SET_DD_CLI_GRP",
        payload
    }
};
export const getDDClientGrpCall = (body) => {
    return getCall(`${baseUrl}/api/listing/client-group`, body, setDDClientGrpAction);
};

/** Group */
export const setDDGrpNameAction = (payload) => {
    return {
        type: "SET_DD_GRP",
        payload
    }
};
export const getDDGrpNameCall = (body) => {
    return getCall(`${baseUrl}/api/listing/group`, body, setDDGrpNameAction);
};

/** Category */
export const setDDCategoryAction = (payload) => {
    return {
        type: "SET_DD_CAT",
        payload
    }
};
export const getDDCategoryCall = (body) => {
    return getCall(`${baseUrl}/api/listing/category`, body, setDDCategoryAction);
};

/** Operation */
export const setDDOperationAction = (payload) => {
    return {
        type: "SET_DD_OPT",
        payload
    }
};
export const getDDOperationCall = (body) => {
    return getCall(`${baseUrl}/api/listing/operation`, body, setDDOperationAction);
};

/** Activity */
export const setDDActivityAction = (payload) => {
    return {
        type: "SET_DD_ACT",
        payload
    }
};
export const getDDActivityCall = (body) => {
    return getCall(`${baseUrl}/api/listing/activity`, body, setDDActivityAction);
};

/** Client */
export const setDDClientAction = (payload) => {
    return {
        type: "SET_DD_CLI",
        payload
    }
};
export const getDDClientCall = (body) => {
    return getCall(`${baseUrl}/api/listing/client`, body, setDDClientAction);
};

/** Series Type */
export const setDDSerTypeAction = (payload) => {
    return {
        type: "SET_DD_SER_TYPE",
        payload
    }
};
export const getDDSerTypeCall = (body) => {
    return getCall(`${baseUrl}/api/listing/seriestype`, body, setDDSerTypeAction);
};

/** Pda CCY */
export const setDDPdaAction = (payload) => {
    return {
        type: "SET_DD_PDA_CCY",
        payload
    }
};
export const getDDPdaCall = (body) => {
    return getCall(`${baseUrl}/api/listing/pda`, body, setDDPdaAction);
};

/** payment-term */
export const setDDPayTermAction = (payload) => {
    return {
        type: "SET_DD_PAY_TERM",
        payload
    }
};
export const getDDPayTermCall = (body) => {
    return getCall(`${baseUrl}/api/listing/payment-term`, body, setDDPayTermAction);
};

/** numberformat */
export const setDDNumFmtAction = (payload) => {
    return {
        type: "SET_DD_NUM_FMT",
        payload
    }
};
export const getDDNumFmtCall = (body) => {
    return getCall(`${baseUrl}/api/listing/numberformat`, body, setDDNumFmtAction);
};

/** business */
export const setDDBuisAction = (payload) => {
    return {
        type: "SET_DD_BUIS",
        payload
    }
};
export const getDDBuisCall = (body) => {
    return getCall(`${baseUrl}/api/listing/business`, body, setDDBuisAction);
};

/** timezone */
export const setDDTimeZAction = (payload) => {
    return {
        type: "SET_DD_TIMEZ",
        payload
    }
};
export const getDDTimeZCall = (body) => {
    return getCall(`${baseUrl}/api/listing/timezone`, body, setDDTimeZAction);
};

/** feature */
export const setDDFeatureAction = (payload) => {
    return {
        type: "SET_DD_FEATURE",
        payload
    }
};
export const getDDFeatureCall = (body) => {
    return getCall(`${baseUrl}/api/listing/feature`, body, setDDFeatureAction);
};

/** basedate */
export const setDDBaseDateAction = (payload) => {
    return {
        type: "SET_DD_B_DATE",
        payload
    }
};
export const getDDBaseDateCall = (body) => {
    return getCall(`${baseUrl}/api/listing/basedate`, body, setDDBaseDateAction);
};

/** agency-role */
export const setDDAgencyRoleAction = (payload) => {
    return {
        type: "SET_DD_AGENT_ROLE",
        payload
    }
};
export const getDDAgencyRoleCall = (body) => {
    return getCall(`${baseUrl}/api/listing/agency-role`, body, setDDAgencyRoleAction);
};

/** account-type */
export const setDDAccTypeAction = (payload) => {
    return {
        type: "SET_DD_ACC_TYPE",
        payload
    }
};
export const getDDAccTypeCall = (body) => {
    return getCall(`${baseUrl}/api/listing/account-type`, body, setDDAccTypeAction);
};

/** reporting-email */
export const setDDReportEmailAction = (payload) => {
    return {
        type: "SET_DD_REPORT_EMAIL",
        payload
    }
};
export const getDDReportEmailCall = (body) => {
    return getCall(`${baseUrl}/api/listing/reporting-email`, body, setDDReportEmailAction);
};

/** alert_type */
export const setDDAlertTypeAction = (payload) => {
    return {
        type: "SET_DD_ALERT_TYPE",
        payload
    }
};
export const getDDAlertTypeCall = (body) => {
    return getCall(`${baseUrl}/api/listing/alert_type`, body, setDDAlertTypeAction);
};

/** Flag */
export const setDDFlagAction = (payload) => {
    return {
        type: "SET_DD_FLAG",
        payload
    }
};
export const getDDFlagCall = (body) => {
    return getCall(`${baseUrl}/api/listing/flag`, body, setDDFlagAction);
};

/** shipsubtype */
export const setDDShipSubTypeAction = (payload) => {
    return {
        type: "SET_DD_SHIP_SUB_TYPE",
        payload
    }
};
export const getDDShipSubTypeCall = (body) => {
    return getCall(`${baseUrl}/api/listing/shipsubtype`, body, setDDShipSubTypeAction);
};

/** shiptype */
export const setDDShipTypeAction = (payload) => {
    return {
        type: "SET_DD_SHIP_TYPE",
        payload
    }
};
export const getDDShipTypeCall = (body) => {
    return getCall(`${baseUrl}/api/listing/shiptype`, body, setDDShipTypeAction);
};

/** ship */
export const setDDShipAction = (payload) => {
    return {
        type: "SET_DD_SHIP",
        payload
    }
};
export const getDDShipCall = (body) => {
    return getCall(`${baseUrl}/api/listing/ship`, body, setDDShipAction);
};

/** shipclass */
export const setDDShipClassAction = (payload) => {
    return {
        type: "SET_DD_SHIP_CLASS",
        payload
    }
};
export const getDDShipClassCall = (body) => {
    return getCall(`${baseUrl}/api/listing/shipclass`, body, setDDShipClassAction);
};

/** shiprole */
export const setDDShipRoleAction = (payload) => {
    return {
        type: "SET_DD_SHIP_ROLE",
        payload
    }
};
export const getDDShipRoleCall = (body) => {
    return getCall(`${baseUrl}/api/listing/shiprole`, body, setDDShipRoleAction);
};

/** requesttype */
export const setDDReqTypeAction = (payload) => {
    return {
        type: "SET_DD_REQ_TYPE",
        payload
    }
};
export const getDDReqTypeCall = (body) => {
    return getCall(`${baseUrl}/api/listing/requesttype`, body, setDDReqTypeAction);
};

/** people_purpose */
export const setDDPurposeAction = (payload) => {
    return {
        type: "SET_DD_PURPOSE",
        payload
    }
};
export const getDDPurposeCall = (body) => {
    return getCall(`${baseUrl}/api/listing/people_purpose`, body, setDDPurposeAction);
};

/** people_gender */
export const setDDGenderAction = (payload) => {
    return {
        type: "SET_DD_GENDER",
        payload
    }
};
export const getDDGenderCall = (body) => {
    return getCall(`${baseUrl}/api/listing/people_gender`, body, setDDGenderAction);
};

/** people_arrival */
export const setDDArrivalAction = (payload) => {
    return {
        type: "SET_DD_ARRIVAL",
        payload
    }
};
export const getDDArrivalCall = (body) => {
    return getCall(`${baseUrl}/api/listing/people_arrival`, body, setDDArrivalAction);
};

/** material_type */
export const setDDMaterialTypeAction = (payload) => {
    return {
        type: "SET_DD_MAT_TYPE",
        payload
    }
};
export const getDDMaterialTypeCall = (body) => {
    return getCall(`${baseUrl}/api/listing/material_type`, body, setDDMaterialTypeAction);
};

/** material_status */
export const setDDMatStatusAction = (payload) => {
    return {
        type: "SET_DD_MAT_STATUS",
        payload
    }
};
export const getDDMatStatusCall = (body) => {
    return getCall(`${baseUrl}/api/listing/material_status`, body, setDDMatStatusAction);
};

/** job_num */
export const setDDJobNumAction = (payload) => {
    return {
        type: "SET_DD_JOB_NUM",
        payload
    }
};
export const getDDJobNumCall = (body) => {
    return getCall(`${baseUrl}/api/listing/job_num`, body, setDDJobNumAction);
};

/** bank */
export const setDDbankAction = (payload) => {
    return {
        type: "SET_DD_BANK",
        payload
    }
};
export const getDDbankCall = (body) => {
    return getCall(`${baseUrl}/api/listing/bank`, body, setDDbankAction);
};

/** client_address */
export const setDDCliAddrAction = (payload) => {
    return {
        type: "SET_DD_CLI_ADDR",
        payload
    }
};
export const getDDCliAddrCall = (body) => {
    return getCall(`${baseUrl}/api/listing/client_address`, body, setDDCliAddrAction);
};

/** activity_unit */
export const setDDActUnitAction = (payload) => {
    return {
        type: "SET_DD_ACT_UNIT",
        payload
    }
};
export const getDDActUnitCall = (body) => {
    return getCall(`${baseUrl}/api/listing/activity_unit`, body, setDDActUnitAction);
};

/** pda_list */
export const setDDPDAlistAction = (payload) => {
    return {
        type: "SET_DD_PDA_LIST",
        payload
    }
};
export const getDDPDAlistCall = (body) => {
    return getCall(`${baseUrl}/api/listing/pda_list`, body, setDDPDAlistAction);
};

/** review_status */
export const setDDReviewStatusAction = (payload) => {
    return {
        type: "SET_DD_REVIEW_STATUS",
        payload
    }
};
export const getDDReviewStatusCall = (body) => {
    return getCall(`${baseUrl}/api/listing/review_status`, body, setDDReviewStatusAction);
};

/** material */
export const setDDMaterialAction = (payload) => {
    return {
        type: "SET_DD_MATERIAL",
        payload
    }
};
export const getDDMaterialCall = (body) => {
    return getCall(`${baseUrl}/api/listing/material`, body, setDDMaterialAction);
};

/** people */
export const setDDPeopleAction = (payload) => {
    return {
        type: "SET_DD_PEOPLE",
        payload
    }
};
export const getDDPeopleCall = (body) => {
    return getCall(`${baseUrl}/api/listing/people`, body, setDDPeopleAction);
};

/** report_type */
export const setDDReportTypeAction = (payload) => {
    return {
        type: "SET_DD_REPORT_TYPE",
        payload
    }
};
export const getDDReportTypeCall = (body) => {
    return getCall(`${baseUrl}/api/listing/report_type`, body, setDDReportTypeAction);
};

/** ship_status */
export const setDDShipStatusAction = (payload) => {
    return {
        type: "SET_DD_SHIP_STATUS",
        payload
    }
};
export const getDDShipStatusCall = (body) => {
    return getCall(`${baseUrl}/api/listing/ship_status`, body, setDDShipStatusAction);
};

/** appointment */
export const setDDAppointmentAction = (payload) => {
    return {
        type: "SET_DD_APPOINTMENT",
        payload
    }
};
export const getDDAppointmentCall = (body) => {
    return getCall(`${baseUrl}/api/listing/appointment`, body, setDDAppointmentAction);
};

/** services_doer */
export const setDDServDoerAction = (payload) => {
    return {
        type: "SET_DD_SERV_DOER",
        payload
    }
};
export const getDDServDoerCall = (body) => {
    return getCall(`${baseUrl}/api/listing/services_doer`, body, setDDServDoerAction);
};

/** services_status */
export const setDDServStatusAction = (payload) => {
    return {
        type: "SET_DD_SERV_STATUS",
        payload
    }
};
export const getDDServStatusCall = (body) => {
    return getCall(`${baseUrl}/api/listing/services_status`, body, setDDServStatusAction);
};
