
export default {
    "formTitle": "Add Feedback ",
    "tableTitle": "Feedback List",
    "tableCol": [
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => row.port,
            sortable: true
        },
        {
            name: 'Ship',
            selector: row => row.ship,
            sortable: true
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: true
        },
        {
            name: 'FDA',
            selector: row => row.fda,
            sortable: true
        },
        {
            name: 'Agent Name',
            selector: row => row.agentName,
            sortable: true
        },
        {
            name: 'name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Designation/Rank',
            selector: row => row.rank,
            sortable: true
        },
        {
            name: 'Location',
            selector: row => row.location,
            sortable: true
        }
    ],
    "formData": [],
    "mockData": []
}