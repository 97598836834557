
import { baseUrl } from "../../constants";
import {
    getDDReviewStatusCall,
    getDDPDAlistCall
} from "../../redux/actions/dropDown";

export default {
    "tableTitle": "Client Reviews",
    "apiDetails": {
        startUp: (dispatch) => {
            [
                getDDReviewStatusCall,
                getDDPDAlistCall
            ].forEach((func) => {
                dispatch(func());
            });
        },
        list: {
            url: `${baseUrl}/api/service/client_review/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/service/client_review`,
        edit: `${baseUrl}/api/service/client_review/edit`,
        delete: `${baseUrl}/api/service/client_review/remove`
    },
    "tableCol": [
        {
            name: 'Ship',
            selector: row => row.ship_DD?.name,
            sortable: true
        },
        {
            name: 'Port',
            selector: row => row.port_DD?.name,
            sortable: true
        },
        {
            name: 'ETA',
            selector: row => row.eta,
            sortable: true
        },
        {
            name: 'PDA Number',
            selector: row => row.pdaNum_DD?.name,
            sortable: true
        },
        {
            name: 'Reviewed By',
            selector: row => row.reviewBy,
            sortable: true
        },
        {
            name: 'Review Date',
            selector: row => row.reviewDate,
            sortable: true
        },
        {
            name: 'Review Status',
            selector: row => row.reviewStatus,
            sortable: true
        },
        {
            name: 'Review Proof',
            selector: row => ((row.reviewProof === 'null' || !row.reviewProof) ? '-' : row.reviewProof),
            sortable: true
        }
    ],
    "formData": [   
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'PDA Number',
                    name: 'pdaNum'
                },
                {
                    type: 'text',
                    label: 'Reviewed By',
                    name: 'reviewBy'
                },
                {
                    type: 'date',
                    label: 'Reviewed Date ',
                    name: 'reviewDate'
                },
                {
                    type: 'select',
                    options: [
                        "Type1",
                        "Type2"
                    ],
                    label: 'Review Status',
                    name: 'reviewStatus'
                },
                {
                    type: 'file',
                    label: 'Review Proof',
                    name: 'reviewProof'
                }
            ]
        },     
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'richText',
                    label: 'Review Comments',
                    name: 'reviewComments'
                }
            ]
        }
    ],
    "mockData": [{
        "pdaNum": 1, // dropdown
        "reviewBy": "sfs",
        "reviewDate": "2024-05-22",//Date
        "reviewStatus": 1,// dropdown static
        "reviewProof": null,//file
        "reviewComments": "sfsf"
    }]
}