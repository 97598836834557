
import { baseUrl } from "../../constants";
import { getDDAgencyCall, getDDGrpNameCall } from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Category",
    "tableTitle": "Category List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDGrpNameCall());
        },
        list: {
            url: `${baseUrl}/api/settings/category/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "groupName"
            }
        },
        add: `${baseUrl}/api/settings/category`,
        edit: `${baseUrl}/api/settings/category/edit`,
        delete: `${baseUrl}/api/settings/category/remove`
    },
    "tableCol": [
        {
            name: 'Group Name',
            selector: row => row.groupName,
            sortable: true
        },
        {
            name: 'Service Code',
            selector: row => row.serviceCode,
            sortable: true
        },
        {
            name: 'Service Category',
            selector: row => row.serviceCategory,
            sortable: true
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Group Name",
                    "name": "groupName",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    required: true
                },
                {
                    type: 'text',
                    label: 'Service Code',
                    name: 'serviceCode',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Service Category',
                    name: 'serviceCategory',
                    required: true
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                }
            ]
        }
    ],
    "mockData": [{
        serviceCode: 'fafj000',
        groupName: 'sfsf',
        serviceCategory: 'Agency',
        description: 'some desc'
    }]
}