import { baseUrl, headers } from "../constants";

export const getCall = (url, body, successCb, errCb, method) => {
    fetch(url, {
        method: method || "POST",
        body: body ? JSON.stringify(body) : null,
        headers
    })
        .then(res => res.json())
        .then(res => {
            successCb(res.data);
        })
        .catch((error, res, a, b) => {
            errCb?.();
            console.error(error);
        })
};

export const getJobCard = (cb, options = {}) => {
    getCall(
        `${baseUrl}/api/service/job/card`,
        {
            sort: 'DESC',
            ...options
        },
        (data) => {
            cb(data);
        }
    );
}

export const getJobDetails = async (body) => {
    return await fetch(`${baseUrl}/api/service/job/list`, {
        method: "POST",
        body: body ? JSON.stringify(body) : null,
        headers
    })
        .then(res => res.json())
        .then(res => {
            return res?.data?.[0];
        })
        .catch((error, res, a, b) => {
            console.error(error);
        });
}

export const setAckCall = (id, cb) => {
    getCall(
        `${baseUrl}/api/service/job/ack`,
        { id, job_status: 'Acknowledged' },
        (data) => {
            console.log(data);
            cb(data);
        },
        (data, res, a, b) => {
            cb(data);
        }
    );
};

export const cancelApp = (id, cb) => {
    getCall(
        `${baseUrl}/api/service/appointment/remove`,
        { id },
        (data) => {
            console.log(data);
            cb?.(data);
        },
        (data, res, a, b) => {
            cb?.(data);
        },
        'put'
    );
};

export const sendPda = (cb, id) => {
    const formData = new FormData();
    formData.append("job_id", id);
    fetch(`${baseUrl}/api/service/pda/send`, {
        method: "POST",
        headers: {
            Accept: "application/pdf, application/xml, application/octetstream"
        },
        body: formData,
    })
        .then(res => res.blob())
        .then(res => {
            console.log(res);
            // successCb(res.data);
        })
        .catch((error, res, a, b) => {
            console.log(error, res, a, b);
            console.error(error);
        })
};

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

function post(path, params, method = 'post') {

    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
}

export const composePda = (data, cb) => {
    // post(
    //     `${baseUrl}/api/service/pda/send/attachment?job_id=${job_id}`, 
    //     data
    // );
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
        // console.log(`${key}: ${value}`);
        if(value) formData.append(key, value);
    }
    // formData.append("job_id", id);
    fetch(`${baseUrl}/api/service/pda/send/attachment`, {
        method: "POST",
        // headers: {
        //     "Content-type": "multipart/form-data"
        // },
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("NETWORK RESPONSE ERROR");
            }
        })
        .then(res => {
            console.log(res);
            if(!res?.data?.error) cb(res.data);
        })
        .catch((error, res, a, b) => {
            console.log(error, res, a, b);
            console.error(error);
        })
};

const downloadFile = (res, fileName) => {
    var url = window.URL || window.webkitURL;
    const link = url.createObjectURL(res);
    var a = document.createElement("a");
    a.setAttribute("download", fileName);
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const pdaPdf = (cb, body, pdaNum) => {
    const formData = new FormData();
    formData.append("job_id", body.job_id);
    fetch(`${baseUrl}/api/service/pda/pdf`, {
        method: "POST",
        headers: {
            Accept: "application/pdf, application/xml, application/octetstream"
        },
        body: formData,
    })
        .then(res => res.blob())
        .then(res => {
            console.log(res);
            const fileName = pdaNum || 'PDA';
            //Check the Browser type and download the File.
            var isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(res, fileName);
            } else {
                downloadFile(res, fileName)
            }
            // successCb(res.data);
        })
        .catch((error, res, a, b) => {
            console.log(error, res, a, b);
            console.error(error);
        })
}

export const letterPdf = (body, cb) => {
    fetch(`${baseUrl}/api/service/pda/invitation`, {
        method: "POST",
        headers: {
            Accept: "application/pdf, application/xml, application/octetstream",
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(body),
    })
        .then(res => res.blob())
        .then(res => {
            console.log(res);
            const fileName = `Letter of Invitaion - ${body.letterBy || ''}`;
            //Check the Browser type and download the File.
            var isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(res, fileName);
            } else {
                downloadFile(res, fileName);
            }
            cb?.();
        })
        .catch((error, res, a, b) => {
            console.log(error, res, a, b);
            console.error(error);
        })
}

export const vesselUpdateCall = (body, cb) => {
    fetch(`${baseUrl}/api/service/pda/vessel`, {
        method: "POST",
        headers: {
            Accept: "application/pdf, application/xml, application/octetstream",
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(body),
    })
        .then(res => res.blob())
        .then(res => {
            console.log(res);
            const fileName = `Vessel Update`;
            //Check the Browser type and download the File.
            var isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(res, fileName);
            } else {
                downloadFile(res, fileName);
            }
            cb?.();
        })
        .catch((error, res, a, b) => {
            console.log(error, res, a, b);
            console.error(error);
        })
}

export const vendorUpdateCall = (body, cb) => {
    fetch(`${baseUrl}/api/service/pda/vendor`, {
        method: "POST",
        headers: {
            Accept: "application/pdf, application/xml, application/octetstream",
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(body),
    })
        .then(res => res.blob())
        .then(res => {
            console.log(res);
            const fileName = `Vendor Update`;
            //Check the Browser type and download the File.
            var isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(res, fileName);
            } else {
                downloadFile(res, fileName);
            }
            cb?.();
        })
        .catch((error, res, a, b) => {
            console.log(error, res, a, b);
            console.error(error);
        })
}
