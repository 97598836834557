
import { baseUrl } from "../../constants";
import { 
    getDDCategoryCall,
    getDDGrpNameCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Operations",
    "tableTitle": "Operation List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDCategoryCall());
            dispatch(getDDGrpNameCall());
        },
        list: {
            url: `${baseUrl}/api/settings/operation/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC"
            }
        },
        add: `${baseUrl}/api/settings/operation`,
        edit: `${baseUrl}/api/settings/operation/edit`,
        delete: `${baseUrl}/api/settings/operation/remove`
    },
    "tableCol": [
        {
            name: 'Group Name',
            selector: row => row.groupName,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true
        },
        {
            name: 'Operation Code',
            selector: row => row.operationCode,
            sortable: true
        },
        {
            name: 'Operation',
            selector: row => row.operation,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Group Name",
                    "name": "groupName",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    required: true
                },
                {
                    "type": "select",
                    "label": "Category",
                    "name": "category",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    required: true
                },
                {
                    type: 'text',
                    label: 'Operation Code',
                    name: 'operationCode',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Operation ',
                    name: 'operation',
                    required: true
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'textArea',
                    label: 'Description',
                    name: 'description'
                }
            ]
        }
    ],
    "mockData": [{
        operation: 'Agency',
        operationCode: 'fafj000',
        groupName: 'sfsf',
        category: 'Agency',
        description: 'some desc'
    }]
}