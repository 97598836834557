
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Add Currency",
    "tableTitle": "Currency List",
    startUp: () => {
    },
    "apiDetails": {
        list: {
            url: `${baseUrl}/api/settings/currency/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 150,
                "sort": "DESC",
                "column": "currency"
            }
        },
        add: `${baseUrl}/api/settings/currency`,
        edit: `${baseUrl}/api/settings/currency/edit`,
        delete: `${baseUrl}/api/settings/currency/remove`
    },
    "tableCol": [
        {
            name: 'Currency Code',
            selector: row => row.currencyCode,
            sortable: true
        },
        {
            name: 'Currency',
            selector: row => row.currency,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Currency',
                    name: 'currency',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Currency Code',
                    name: 'currencyCode',
                    required: true
                }
            ]
        }
    ],
    "mockData": [{
        currency: 'India',
        currencyCode: 'INR'
    }]
}