export default {
    "tableTitle": "Receivable List",
    "hideForm":true,
    "tableCol": [
        {
            name: 'Ship & Port',
            selector: row => row.shipPort,
            sortable: true
        },
        {
            name: 'Appointment',
            selector: row => row.appointment,
            sortable: true
        },
        {
            name: 'Client Name',
            selector: row => row.clientName,
            sortable: true
        },
        {
            name: 'FDA Sent Date (Invoice Date)',
            selector: row => row.fdaSentDate,
            sortable: true
        },
        {
            name: 'FDA Invoice',
            selector: row => row.fdaInvoice,
            sortable: true
        },
        {
            name: 'Invoice No',
            selector: row => row.invoiceNo,
            sortable: true
        },
        {
            name: 'Currency',
            selector: row => row.currency,
            sortable: true
        }
    ],
    "formData": []}