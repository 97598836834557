import React, {
    useEffect,
    useState
} from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { setApiAlert } from './redux/actions/common';
import { getApiAlert } from './redux/selector';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard/dashboard';
import './App.css';

function App() {
    const apiAlert = useSelector(getApiAlert);
    const [errorDetails, setErrorDetails] = useState(apiAlert);
    const [page, setPage] = useState('login');
    const changePage = (pageName) => {
        setPage(pageName);
    };
    const handleClose = () => {
        setErrorDetails(false);
    };

    useEffect(()=>{
        setErrorDetails(apiAlert);
    }, [apiAlert]);

    const pagesArr = ['dash', 'profile'];
    return (
        <div className={`App ${page}`}>
            <Snackbar open={errorDetails}
                autoHideDuration={errorDetails?.duration || 5000}
                onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={errorDetails?.error ? "error" : "success"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {errorDetails?.msg}
                </Alert>
            </Snackbar>
            {page === 'login' ? <Login changePage={changePage} /> : ''}
            {pagesArr.includes(page) ? <Dashboard changePage={changePage} page={page} /> : ''}
        </div>
    );
}

export default App;
