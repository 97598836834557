import React, {
    useEffect,
    useState
} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './styles.css';
import ShipCard from '../../components/card/card';
import TimelineComp from '../../components/timeline/timeLine';
import { getJobCard } from '../../services';
import ViewModal from '../../components/viewModal/viewModal';
// import { useDispatch } from 'react-redux'; 
// import { 
//     getDDPdaCall,
//     getDDJobNumCall,
//     getDDAppointmentCall
// } from "../../redux/actions/dropDown";
import './styles.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
function a11yProps1(index) {
    return {
        id: `horizontal-tab-${index}`,
        'aria-controls': `horizontal-tabpanel-${index}`,
    };
}

export function ColorTabs({
    jobList,
    refreshJobCard
}) {
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    
  const handleClose = (value) => {
    setOpen(false);
  };
  const timelineProps = {
      handleClose,
      open
  };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const subTabsArr = [
        "All Jobs",
        "My Jobs",
        "ACK Pending",
        "PDA Pending",
        "Client Reivew Pending",
        "PDA Rejected",
        "Cancelled Jobs"
    ];

    return (
        <Box sx={{ width: '100%' }} className="tab-wrapper">
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="secondary tabs example"
                sx={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    backgroundColor: "rgb(255 255 255 / 50%)",
                    borderRadius: "5px",
                    boxShadow: '6px 16px 31px -18px #b7bcd1'
                  }}
                  className='subTab'
            >
                {subTabsArr.map((tabName, index) => <Tab label={tabName} {...a11yProps1(index)} />)}
            </Tabs>
            {subTabsArr.map((tabName, index) => <TabPanel value={value} index={index}>
                <div className='card-list-wrapper'>
                    {jobList.map((item)=><ShipCard 
                    key={item?.jobNum}
                    setOpen={setOpen} 
                    jobDetails={item} 
                    refreshJobCard={refreshJobCard}
                    />)}
                </div>                
                <TimelineComp {...timelineProps }/>
            </TabPanel>)}
        </Box>
    );
}

export default function VerticalTabs() {
    const [value, setValue] = React.useState(0);
    const [jobList, setJobList] = React.useState([]);
    const [openView, setOpenView] = useState(false);
    const [viewProps, setViewProps] = useState({});
    // const dispatch = useDispatch();

    const tabsArr = [
        'All',
        'Enquiry',
        'Nominations',
        'FDA'
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
        refreshJobCard(newValue)
    };

    const refreshJobCard = (requestType = 0) => {
        getJobCard((data)=>{
            setJobList(data);
        }, {
            requestType
        });
        // [
        //     getDDPdaCall,
        //     getDDJobNumCall,
        //     getDDAppointmentCall
        // ].forEach((func) => {
        //     dispatch(func());
        // });
    };

    useEffect(()=>{
        refreshJobCard();
    }, []);


    return (
        <Box
            sx={{ flexGrow: 1, display: 'flex' }}
        >
        <div class="bul-search-header" style={{marginLeft: '1px'}}>
        <button class="btn" onClick={()=>{                    
            setOpenView(true);
            setViewProps({
                isEdit: true,
                isAdd: true,
                menuName: 'jobs',
                dValue: {},
                title: 'Add Job',
                closeCb: ()=>{                                            
                    refreshJobCard();
                }
            })
        }}>Add Job</button>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider',
                width: '300px' }}
            >
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{
                        width: '100px'
                    }} className="date-wrapper" />
                </LocalizationProvider> */}
                {tabsArr.map((tabName, index) => <Tab label={tabName} {...a11yProps(index)} />)}
            </Tabs>            
        </div>
            {tabsArr.map((_tabName, index) => <TabPanel value={value} index={index}>
                <ColorTabs jobList={jobList} refreshJobCard={refreshJobCard} />
            </TabPanel>)}
            <ViewModal
                open={openView}
                setOpen={setOpenView}
                {...viewProps}
            />
        </Box>
    );
}
