import React, {
    useState
} from 'react';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';

import { useSelector } from 'react-redux';

import { setCookie } from '../../utils';
import { getUserProfile } from '../../redux/selector';
import MenuList from '../menu/menu';
import './styles.css';

function HeaderComponent({
    setSelectedSubMenu,
    setSelectedMenu,
    changePage
}) {
    const profileData = useSelector(getUserProfile);
    const isAdmin = profileData?.userRole === 3;
    const [showSearch, setShowSearch] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const mainMenus = [
        {
            menuName: "Services",
            subMenu: [
                {
                    label: "Jobs",
                    name: 'jobs'
                },
                {
                    label: "PDA",
                    name: 'pda'
                },
                {
                    label: "Client Review",
                    name: 'clientReviews'
                },
                {
                    label: "Appointments",
                    name: 'appointments'
                },
                {
                    label: "DSR",
                    name: 'dsr'
                },
                {
                    label: "People",
                    name: 'people'
                },
                {
                    label: "Materials",
                    name: 'materials'
                }
            ]
        },
        {
            menuName: "Ship",
            subMenu: [
                {
                    label: "Type",
                    name: 'shipType'
                },
                {
                    label: "Sub Type",
                    name: 'shipSubType'
                },
                {
                    label: "Class",
                    name: 'shipClass'
                },
                {
                    label: "Ship",
                    name: 'ship'
                }
            ]
        },
        {
            menuName: "Post Ops",
            subMenu: [
                {
                    label: "FDA",
                    name: 'mock'
                },
                {
                    label: "Vendor Invoices",
                    name: 'mock'
                },
                {
                    label: "TBC",
                    name: 'mock'
                },
                {
                    label: "Vendor CN",
                    name: 'mock'
                },
                {
                    label: "Client CN",
                    name: 'mock'
                }
            ]
        },
        {
            menuName: "Pricing",
            subMenu: [
                {
                    label: "Vendor Prices",
                    name: 'mock'
                },
                {
                    label: "Agency Prices",
                    name: 'mock'
                },
                {
                    label: "Formula Fields",
                    name: 'mock'
                },
                {
                    label: "Formulae",
                    name: 'mock'
                }
            ]
        },
        {
            menuName: "Agencies",
            subMenu: [
                {
                    label: "Setup Agency",
                    name: 'agency'
                },
                isAdmin && {
                    label: "Setup Agency Users",
                    name: 'agencyUser'
                },
                isAdmin && {
                    label: "Email setup",
                    name: 'agencyEmail'
                },
                {
                    label: "Client",
                    name: 'client'
                },
                {
                    label: "Vendors",
                    name: 'vendor'
                },
                {
                    label: "Client Address Book",
                    name: 'clientAddr'
                },
                isAdmin && {
                    label: "Terms",
                    name: 'terms'
                },
                isAdmin && {
                    label: "SLA",
                    name: 'sla'
                },
                isAdmin && {
                    label: "Bank Info",
                    name: 'bankInfo'
                }
            ]
        },
        // {
        //     menuName: "Profile",
        //     subMenu: [
        //         // {
        //         //     label: "Update Profile",
        //         //     name: 'mock'
        //         // },
        //         // {
        //         //     label: "Change Password",
        //         //     name: 'mock'
        //         // },
        //         {
        //             label: "Manage Users",
        //             name: 'manageUser'
        //         }
        //     ]
        // },
        {
            menuName: "Settings",
            subMenu: [
                {
                    label: "Region",
                    name: 'region'
                },
                {
                    label: "Country",
                    name: 'country'
                },
                {
                    label: "Port",
                    name: 'port'
                },
                {
                    label: "Group",
                    name: 'group'
                },
                {
                    label: "Category",
                    name: 'category'
                },
                {
                    label: "Operations",
                    name: 'operation'
                },
                {
                    label: "Activities",
                    name: 'activity'
                },
                {
                    label: "Map Activities",
                    name: 'operationMap'
                },
                {
                    label: "Currencies",
                    name: 'currency'
                },
                {
                    label: "Number Format",
                    name: 'numFormat'
                }
            ]
        },
        isAdmin && {
            menuName: "System",
            subMenu: [
                {
                    label: "Lookup",
                    name: 'lookUp'
                },
                {
                    label: "Alert Templates",
                    name: 'alertTemp'
                },
                {
                    label: "Notification Logs",
                    name: 'mailLogs'
                },
                {
                    label: "Units",
                    name: 'unit'
                },
                {
                    label: "Job Nature",
                    name: 'jobNature'
                },
                {
                    label: "Cargo UoM",
                    name: 'cargoUom'
                }
            ]
        },
        {
            menuName: "Finance",
            subMenu: [
                {
                    label: "Prefund Request",
                    name: 'prefundRequest'
                },
                {
                    label: "Prefund Receipts",
                    name: 'prefundReceipts'
                },
                {
                    label: "Vendor Prefunding",
                    name: 'vendorPrefunding'
                },
                {
                    label: "Payables",
                    name: 'payables'
                },
                {
                    label: "Receivables",
                    name: 'receivables'
                },
                {
                    label: "Expenses",
                    name: 'expenses'
                },
                {
                    label: "Charges",
                    name: 'charges'
                },
                {
                    label: "Invoice Settings",
                    name: 'invoiceSettings'
                },
                {
                    label: "Direct Profit",
                    name: 'directProfit'
                }
            ]
        },
        {
            menuName: "Reports",
            subMenu: [
                {
                    label: "Accrual",
                    name: 'mock'
                },
                {
                    label: "Job Audit Log",
                    name: 'mock'
                },
                {
                    label: "Client Report",
                    name: 'mock'
                },
                {
                    label: "Vendor Report",
                    name: 'mock'
                },
                {
                    label: "P&L Report",
                    name: 'mock'
                },
                {
                    label: "Usage Report",
                    name: 'mock'
                }
            ]
        },
        {
            menuName: "Analytics",
            subMenu: [
                {
                    label: "Job Metrics",
                    name: 'mock'
                },
                {
                    label: "Appointment Metrics",
                    name: 'mock'
                },
                {
                    label: "FDA Metrics",
                    name: 'mock'
                },
                {
                    label: "Vendor PO Metrics",
                    name: 'mock'
                },
                {
                    label: "Receivable Metrics",
                    name: 'mock'
                }
            ]
        }
    ];

    return (<>
        <div className="header-wrapper">
            <div className="header-bar">
            <img onClick={() => {
                setSelectedMenu('dash');
                setSelectedSubMenu('');
            }} src="img/logo.png" alt="Bulbous" className='logo' />


            <div class="bul-head-userprofile">
                <div class="navbar-collapse collapse">
                    <ul class="navbar-nav navbar-align">
                        <li class="bul-head-search">
                            <TextField
                                id="search-bar"
                                className="text"
                                onInput={(e) => {
                                    console.log(e.target.value);
                                }}
                                label="Search"
                                variant="outlined"
                                placeholder="Search..."
                                size="small"
                                sx={{
                                    position: 'relative',
                                    left: showSearch ? '0' : '500px',
                                    transition: 'left 1s'
                                }}
                            />
                            <div>
                                <IconButton type="submit" aria-label="search"
                                    sx={{
                                        color: 'rgb(103,36,240)'
                                    }}
                                    onClick={() => {
                                        setShowSearch(!showSearch);
                                    }}
                                >
                                    <SearchIcon style={{ fill: "rgb(103,36,240)" }} />
                                </IconButton>
                            </div>
                        </li>
                        <li class="nav-item dropdown avatar-border bul-top-user-li">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar 
                                sx={{ width: 32, height: 32, backgroundColor: 'rgb(103,36,240)' }}>
                                    {profileData?.firstName?.substring?.(0,1).toUpperCase()}
                                </Avatar>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={() => {                                   
                                    setSelectedMenu('profile');
                                    setSelectedSubMenu('');
                                    handleClose();
                                }}>
                                    <Avatar /> Profile
                                </MenuItem>
                                <MenuItem onClick={()=>{
                                    setCookie('fluidUser', '', -1);
                                    changePage('login');
                                    handleClose();
                                }}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </li>
                    </ul>
                </div>
            </div>
            </div>

            
        <div className='menus-wrapper'>
            {mainMenus.map((menuDetails) => (menuDetails?.menuName ? 
                <MenuList {...menuDetails} setSelectedSubMenu={setSelectedSubMenu} /> :
            ''))}
        </div>
        </div>
    </>
    );
}

export default HeaderComponent;
