import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EmailComp from "../emailComp/emailComp";

const SendMailPopUp = ({ open, setOpen, onSend }) => {

    const [toMail, setToMail] = useState([]);
    const [ccMail, setCcMail] = useState([]);
    const [bccMail, setBccMail] = useState([]);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>Send Mail</DialogTitle>
            <DialogContent>
                <div className="compose-field-wrapper">
                    <h3>To</h3>
                    <EmailComp emails={toMail} setEmails={setToMail} />
                </div>
                <div className="compose-field-wrapper">
                    <h3>CC</h3>
                    <EmailComp emails={ccMail} setEmails={setCcMail} />
                </div>
                <div className="compose-field-wrapper">
                    <h3>BCC</h3>
                    <EmailComp emails={bccMail} setEmails={setBccMail} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" onClick={()=>{
                    onSend({
                        'mailTo':toMail.join(','),
                        'ccTo': ccMail.join(','),
                        'bccTo': bccMail.join(',')
                    });
                    handleClose();
                }}>Send Mail</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SendMailPopUp;
