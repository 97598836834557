
import { baseUrl } from "../../constants";
import { 
    getDDAgencyCall,
    getDDCountryCall,
    getDDPayTermCall,
    getDDBuisCall
} from "../../redux/actions/dropDown";

export default {
    "formTitle": "Add Vendor",
    "tableTitle": "Vendors List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(getDDAgencyCall());
            dispatch(getDDCountryCall());
            dispatch(getDDPayTermCall());
            dispatch(getDDBuisCall());
        },
        list: {
            url: `${baseUrl}/api/agency/vendor/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "clientGrp"
            }
        },
        add: `${baseUrl}/api/agency/vendor`,
        edit: `${baseUrl}/api/agency/vendor/edit`,
        delete: `${baseUrl}/api/agency/vendor/remove`
    },
    "tableCol": [
        {
            name: 'Vendor Code',
            selector: row => row.vendorCode,
            sortable: true
        },
        {
            name: 'Vendor Name',
            selector: row => row.vendorName,
            sortable: true
        },
        {
            name: 'Primary PIC',
            selector: row => row.primaryPic,
            sortable: true
        },
        {
            name: 'Primary Email',
            selector: row => row.primaryEmail,
            sortable: true
        },
        {
            name: 'Primary Phone',
            selector: row => row.primaryPhone,
            sortable: true
        },
        {
            name: 'Nature of Business',
            selector: row => row.buisnessNature,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    "type": "select",
                    "label": "Agency",
                    "name": "agency",
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    "required": true
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Basic Details',
            "formFields": [
                {
                    type: 'text',
                    label: 'Vendor Code',
                    name: 'vendorCode'
                },
                {
                    type: 'text',
                    label: 'Vendor Name',
                    name: 'vendorName',
                    required: true
                },
                {
                    type: 'file',
                    label: 'Primary PIC',
                    name: 'primaryPic'
                },
                {
                    type: 'text',
                    label: 'Primary Phone',
                    name: 'primaryPhone'
                },
                {
                    type: 'text',
                    label: 'Primary Email',
                    name: 'primaryEmail'
                },
                {
                    type: 'select',
                    label: 'Billing To',
                    name: 'billTo',
                    "options": [
                        "Self",
                        "Group"
                    ],
                    required: true
                },
                {
                    type: 'select',
                    label: 'Payment Terms',
                    name: 'payTerm',
                    "options": [
                        "Gateway",
                        "Quixlab"
                    ],
                    required: true
                },
                // {
                //     type: 'select',
                //     label: 'Vendor Group',
                //     name: 'vendorGrp',
                //     "options": [
                //         "Vendor1",
                //         "Vendor2"
                //     ]
                // },
                {
                    type: 'select',
                    label: 'Nature of Business',
                    name: 'businessNature',
                    "options": [
                        "Agency",
                        "Sub-Agency"
                    ]
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Vendor Address',
            "formFields": [
                {
                    type: 'select',
                    label: 'Country',
                    name: 'country',
                    "options": [
                        "India",
                        "China"
                    ],
                    required: true
                },
                {
                    type: 'text',
                    label: 'Address Line 1',
                    name: 'addrLine1',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Address Line 2',
                    name: 'addrLine2'
                },
                {
                    type: 'text',
                    label: 'City',
                    name: 'city'
                },
                {
                    type: 'text',
                    label: 'State / Province',
                    name: 'state'
                }
            ]
        },
        {
            "formType": "box",
            formTitle: 'Bank Details',
            "formFields": [
                {
                    type: 'text',
                    label: 'Bank Name',
                    name: 'bankName'
                },
                {
                    type: 'text',
                    label: 'Account Number',
                    name: 'accNum'
                },
                {
                    type: 'text',
                    label: 'Account Name',
                    name: 'accName'
                },
                {
                    type: 'text',
                    label: 'Routing Number',
                    name: 'routeNum'
                }
            ]
        },
        {
            formType: 'list',
            formTitle: 'Vendor Contacts',
            formName: 'contacts',
            formFields: [
                {
                    type: 'text',
                    label: 'Name',
                    name: 'name',
                    required: true
                },
                {
                    type: 'text',
                    label: 'Email',
                    name: 'email'
                },
                {
                    type: 'number',
                    label: 'Phone',
                    name: 'phone'
                },
                {
                    type: 'select',
                    label: 'Roles',
                    name: 'roles',
                    "options": [
                        "Admin",
                        "User"
                    ]
                }
            ]
        },
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'richText',
                    label: 'Notes',
                    name: 'notes'
                }
            ]
        }
    ],
    "mockData": [{
        "agency": "Gateway",
        "vendorCode": "fdsf23424",
        "vendorName": "dvdv",
        "primaryPic": "vcvdv",
        "primaryPhone": "3435",
        "primaryEmail": "dvdg",
        "billTo": "Self",
        "payTerm": "Gateway",
        "vendorGrp": "Vendor1",
        "businessNature": "Agency",
        "country": "India",
        "addrLine1": "dgfdvfb",
        "addrLine2": "dbcb",
        "city": "dgdg",
        "state": "ddv",
        "bankName": "fdgfdg",
        "accNum": "3434",
        "accName": "dvdv",
        "routeNum": "334",
        "contacts": [
            {
                "name": "dfdfb",
                "email": "dgdfg",
                "phone": "33",
                "roles": ""
            }
        ],
        "notes": null
    }]
}