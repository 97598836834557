import React, {
    useEffect,
    useState
} from 'react';

import './styles.css';
import ProfilePage from '../profile/profile';
import TableFormView from '../../components/tableForm/tableFormView';
import TableFormDialog from '../../components/tableForm/tableFormDialog';
import HeaderComponent from '../../components/header/header';
import DashContent from './dashContent';
import { getCookie, setCookie } from '../../utils';
import '../../index.css';

function Dashboard({ changePage, page }) {
    const menuDetails = getCookie('menuDetails');
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(page);
    const [selectedSubMenu, setSelectedSubMenu] = useState(menuDetails);

    useEffect(()=>{
        try{
            setSelectedSubMenu(JSON.parse(menuDetails) || '');
        }
        catch(err){
            console.log('err -- ',err);
        }
    }, []);

    useEffect(()=>{
        setCookie('menuDetails', JSON.stringify(selectedSubMenu), 0.1);
    },[selectedSubMenu]);

    const handleMenuVisible = () => {
        setMenuCollapse(!menuCollapse);
    };
    return <>
        <HeaderComponent
            setSelectedSubMenu={setSelectedSubMenu}
            setSelectedMenu={setSelectedMenu}
            changePage={changePage}
        />
        <section>
            <div class="container-main">
                <div class="container-fluid">
                    <main>
                        {(selectedMenu === 'profile' && !selectedSubMenu) ? <ProfilePage /> : <>
                            <div style={{
                                display: selectedSubMenu ? '' : 'none'
                            }}>
                                <TableFormDialog menuName={selectedSubMenu?.subMenu} />
                            </div>
                            {/* {selectedSubMenu ? <TableFormView menuName={selectedSubMenu?.subMenu} /> : ''} */}
                            {!selectedSubMenu ? <DashContent /> : ''}
                        </>}
                    </main>
                </div>
            </div>
        </section></>;
}

export default Dashboard;
